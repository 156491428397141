@import "../../../assets/styles/global.scss";

.support_cause_slide_container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .icon_wrapper {
    width: 25%;

    .cause_icon {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text_wrapper {
    width: 75%;

    .main_heading {
      font-size: $normal-font-size;
      color: $text-color-dark;
      margin-bottom: 15px;
    }

    .info_text {
      font-size: $small-font-size;
      color: $text-color-light;
      margin-bottom: 15px;
    }

    .info_link {
      text-decoration: underline;
      color: $element-active-color;
      font-size: $small-font-size;

      &:hover,
      &:visited {
        filter: brightness(1.05);
      }
    }
  }
}
