.personal_details_skeleton {
  width: 100%;
  margin: 20px auto;
  position: relative;

  [aria-live][aria-busy] {
    display: block;
    width: 100%;
  }

  .business_logo {
    margin: 0 auto;
    display: block;
    border-radius: 10px;
  }

  .text {
    display: block;
    margin: 0px auto;
    line-height: 24px;
    --base-color: #9e9e9e;
    --highlight-color: #c1baba;
  }

  .main_bg {
    width: 100%;
    height: 230px;
    border-radius: 10px 10px 0 0;
    margin-top: 95px;
  }

  .profile_picture {
    width: 170px;
    height: 170px;
    border-radius: 10px;
    margin: 10px auto 20px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgb(0 0 0 / 8%);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .name {
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .job {
    position: absolute;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .contact_div {
    margin-top: -90px;
    position: relative;
    height: 70px;

    padding: 10px 10px 20px;

    .contact_div_inner {
      border-radius: 0 0 10px 10px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .contact_flex {
      margin-top: -12px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .contact_icon_container {
        width: auto;

        .contact_icon {
          display: block;
          width: 40px;
          height: 40px;
          --base-color: #9e9e9e;
          --highlight-color: #c1baba;
        }
      }
    }
  }
}
