@import "../../../assets/styles/variables.scss";

.analytic_section {
  position: relative;
  background-color: $designer-component-bg;
  padding: 20px 16px;
  margin: 8px 0;

  .content_section {
    display: flex;
    justify-content: space-between;

    .single_item {
      text-align: center;
      .title_section {
        display: flex;
        align-items: center;
        .title {
          font-size: 11px;
        }

        svg {
          width: 8px;
          height: 8px;
          margin-right: 5px;
          margin-bottom: 1px;
        }
      }
      .views {
        svg path,
        svg rect {
          fill: #81F2F9;
        }
      }
      
      .clicks {
        svg path,
        svg rect {
          fill: $element-active-color;
        }
      }

      .unique {
        svg path,
        svg rect {
          fill: #75D89D;
        }
      }
      
      .ctr {
        svg path,
        svg rect {
          fill: #FCC97B;
        }
      }

      .time {
        svg path,
        svg rect {
          fill: #E97D85;
        }
      }
      
      .earning {
        svg path,
        svg rect {
          fill: #FF81DC;
        }
      }

      .value {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.analytic_bar {
  position: relative;
  background-color: $designer-component-bg;
  padding: 20px 16px;
  margin: 8px 0;

  .content_section {
    display: flex;
    justify-content: space-between;

    .single_item {
      text-align: center;
      .title_section {
        display: flex;
        align-items: center;
        .title {
          font-size: 11px;
        }

        svg {
          width: 8px;
          height: 8px;
          margin-right: 5px;
          margin-bottom: 1px;
        }
      }
      .views {
        svg path,
        svg rect {
          fill: #81F2F9;
        }
      }
      
      .clicks {
        svg path,
        svg rect {
          fill: $element-active-color;
        }
      }

      .unique {
        svg path,
        svg rect {
          fill: #75D89D;
        }
      }
      
      .ctr {
        svg path,
        svg rect {
          fill: #FCC97B;
        }
      }

      .time {
        svg path,
        svg rect {
          fill: #E97D85;
        }
      }
      
      .earning {
        svg path,
        svg rect {
          fill: #FF81DC;
        }
      }

      .value {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .chart_container {
    display: flex;
    justify-content: center;
  }

  .bar_top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date_section {
      font-size: 12px;
      font-weight: 700;
      color: $text-color-dark;
    }

    .floating_save_button {
      color: $danger-color;
      font-size: 15px;
      font-weight: 500;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: $element-active-color-light-bg;
      color: $element-active-color;
      opacity: 1;
      pointer-events: unset;
      border: 1px solid $element-active-color;
  
      svg {
        width: 15px;
        height: 15px;
        margin-top: 2px;
        background-color: $element-active-color-light-bg;
        color: $element-active-color;
        margin-left: 8px;
  
        path,
        rect {
          fill: $element-active-color;
        }
      }
  
      &.show {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }

  .section_top {
    display: flex;
    justify-content: space-between;

    .section_options {
      display: flex;
      font-size: 12px;
      color: $text-color-dark;

      p {
        margin-right: 16px;
        padding-bottom: 3px;
        position: relative;
      }

      .selected_option {
        position: relative;
        &::after {
          content: "";
          // display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $element-active-color;
        }
      }
    }
  }

  .info_section {
    font-size: 13px;
    color: $text-color-dark;
  }
}