@import "../../assets/styles/variables.scss";

.profile {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;

  * {
    transition: all 300ms ease-in-out;
  }

  .tabs {
    width: 100%;
    margin-top: 25px;
    padding: 20px 0;
    display: flex;
    flex-direction: row;

    .tab {
      width: 25%;
      padding-bottom: 8px;
      border-bottom: 2px solid #bbbbbb;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        padding-right: 6px;

        span,
        svg {
          width: 100%;
          height: 100%;
          display: flex;
          path {
            fill: $default-section-bg;
          }
        }

        .text {
          padding-left: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: $default-section-bg;
        }
      }

      &:visited {
        color: inherit;
      }

      &.active,
      &:hover {
        border-bottom-color: $element-active-color;
      }
    }
  }

  .tabBody {
    display: block;
    width: 100%;
    min-height: 100%;
    padding: 12px;

    .inner {
      display: block;
      width: 100%;
      height: 100%;

      .tab_heading {
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }

      .inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 12px 0;

        form {
          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .input {
          width: calc(100% / 3);
          padding: 6px;
        }

        .full_width {
          width: 100%;
          margin-top: 10px;
          padding-left: 6px;
        }

        .info,
        .error {
          font-size: 14px;
          margin: 6px 0;
        }

        .info {
          color: $element-active-color;
        }

        .error {
          color: $danger-color;
        }
      }

      .divider {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #bbbbbb;
        margin: 45px 0;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile {
    .tabs {
      .tab {
        .icon {
          width: 30px;
          height: 30px;
        }

        .text {
          display: none;
        }
      }
    }

    .tabBody {
      .inner {
        .inputs {
          .input {
            width: 100%;
          }
        }
      }
    }
  }
}
