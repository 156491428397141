@import "../../../../assets/styles/variables.scss";

.share_heading {
  font-size: $semi-small-font-size;
  font-weight: 700;
  color: #354640;
  margin-bottom: 12px;
  text-align: left;
}

.share_subheading {
  color: $text-color-light !important;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 12px;
}

.popup_share_btns {
  & > div {
    display: none !important;
  }
}

.share_btns {
  position: relative;
  margin: 20px 0;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #dddfe2 !important;
    padding-bottom: 8px !important;
    margin-bottom: 8px;
    width: 100%;

    span.share_text {
      margin-left: 6px;
      text-align: left;
    }

    &:hover {
      border-bottom-color: $element-active-color !important;
    }
  }
}

.copy_url {
  background: #f5f5f5;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 11px 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .copy_text {
    font-size: 14px;
    color: #505050;
    text-align: left;
    width: calc(100% - 30px);
  }

  .copy_icon {
    width: 14px;
    height: 14px;
    display: block;

    span {
      display: block;
      width: 100%;
      height: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.copied {
    border-color: $element-active-color;

    .copy_text,
    svg path {
      color: $element-active-color;
      fill: $element-active-color;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .popup_share_btns {
    & > div {
      display: flex !important;
    }
  }
}
