@import "../../../assets/styles/variables.scss";

.umi_button {
  display: block;
  width: 100%;
  margin: 15px auto;
  padding: 15px 20px;
  color: $element-active-color;
  background-color: $element-light-blue-color;
  font-size: $normal-font-size;
  font-weight: 700;

  &.hide_contacts_button {
    width: auto;
    border-radius: 10px;
    margin: 0;
  }

  &.submit_lead_button {
    width: auto;
    float: right;
    background-color: $element-active-color;
    color: #ffffff;
    padding: 15px 40px;
  }

  &.submit_password_gallery_button {
    width: auto;
    float: unset;
    margin: unset;
    background-color: $element-active-color;
    color: #ffffff;
    padding: 15px 40px;
  }

  &.profile_update_button {
    width: auto;
    float: unset;
    margin: unset;
    display: block;
    background-color: $element-active-color;
    color: #ffffff;
    padding: 12px 40px;
  }

  &.confirmation_cancel {
    background-color: $element-active-color;
    color: #ffffff;
  }

  &.confirmation_confirm {
    background-color: $danger-color-dark;
    color: #ffffff;
    border: none;
  }

  &.blue_centered {
    width: auto;
    float: unset;
    margin: 10px auto;
    display: block;
    background-color: $element-active-color;
    color: #ffffff;
    padding: 12px 40px;
  }
}
