.social_links_skeleton {
  width: 100%;
  margin: 20px auto;
  position: relative;

  [aria-live][aria-busy] {
    display: block;
    width: 100%;
  }

  .social_container {
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
  }

  .icons_flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: 25%;
      height: 72px;
      padding: 6px;

      .social_icon {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        --base-color: #39b1ee;
        --highlight-color: #81caef;
      }
    }
  }
}
