@import "../../../assets/styles/variables.scss";

.toc_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: $element-active-color;
  color: #ffffff;
  height: 52px;

  .copy {
    font-size: 14px;
  }
  .link {
    color: #ffffff;
    font-size: 14px;
    margin-left: 12px;
    display: inline-block;
    color: inherit;
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .toc_footer {
    height: auto;

    .right {
      display: flex;
      flex-direction: column;

      .link {
        margin-left: 0;
        margin-bottom: 6px;
      }
    }
  }
}
