@import "../../../assets/styles/global.scss";

.checkbox_container {
  position: relative;

  .required {
    color: $danger-color;
    font-size: 28px;
    position: absolute;
    left: -16px;
  }

  .checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: $normal-font-size;
      font-weight: bold;
      color: $text-color-dark;
      opacity: 0.6;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid #ababab;
      border-radius: 3px;
    }

    &:checked + label {
      opacity: 1;
    }

    // Box hover
    &:hover + label:before {
      background: $element-active-color;
    }

    // Box focus
    &:focus + label:before {
      box-shadow: $component-box-shadow;
    }

    // Box checked
    &:checked + label:before {
      background: $element-active-color;
      border-color: $element-active-color;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 10px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }

  &.social_icons_style_checkbox {
    width: 50px;
    margin-top: 25px;

    .social_icon_display_container {
      position: absolute;
      top: -40px;
      left: -7px;
      width: 35px;
      height: 35px;
      border: 1px solid black;
      border-radius: 50%;

      span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 60%;
          height: auto;

          path {
            fill: #fff;
            stroke: #000;
          }
        }
      }

      &.social_icon_style_2 {
        background-color: #000;

        svg path {
          fill: #fff;
          stroke: none;
        }
      }
      &.social_icon_style_3 {
        border-radius: 0;

        svg path {
          fill: #000;
          stroke: none;
        }
      }
      &.social_icon_style_4 {
        border-radius: 0;
        background-color: #000;

        svg path {
          fill: #fff;
          stroke: none;
        }
      }
      &.social_icon_style_5 {
        border-radius: 6px;

        svg path {
          fill: #000;
          stroke: none;
        }
      }
      &.social_icon_style_6 {
        border-radius: 6px;
        background-color: #000;

        svg path {
          fill: #fff;
          stroke: none;
        }
      }
      &.social_icon_style_7 {
        border-radius: 6px;
        background-color: #388acc;
        border-color: #388acc;

        svg path {
          fill: #fff;
          stroke: none;
        }
      }
      &.social_icon_style_8 {
        border-radius: 50%;
        background-color: #388acc;
        border-color: #388acc;

        svg path {
          fill: #fff;
          stroke: none;
        }
      }
    }
  }

  &.profile_pic_border_style_checkbox {
    width: 50px;
    margin-top: 25px;

    .profile_icon_display_container {
      position: absolute;
      top: -40px;
      left: -7px;
      width: 35px;
      height: 35px;

      span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &.rounded_border {
        span img {
          border-radius: 6px;
        }
      }

      &.square_border {
        span img {
          border-radius: 0;
        }
      }

      &.circle_border {
        span img {
          border-radius: 50%;
        }
      }
    }
  }

  &.image_link_style_checkbox {
    width: 100px;
    margin-top: 25px;

    .checkbox {
      & + label:before {
        margin-left: 16px;
      }
      &:checked + label:after {
        left: 22px;
      }
    }

    .image_link_icon_display_container {
      position: absolute;
      top: -40px;
      left: -7px;
      width: 70px;
      height: 35px;
      background-color: #f0f0f0;
      border: 1px solid #858585;

      &.image_link_icon_style_1 {
        border-radius: 0px;
      }
      &.image_link_icon_style_2 {
        border-radius: 4px;
      }
      &.image_link_icon_style_3 {
        border-radius: 0 16px 16px 0;
      }
      &.image_link_icon_style_4 {
        border-radius: 16px 0 0 16px;
      }
    }
  }

  &.checkbox_with_icon_on_left {
    border: $add-section-btn-border;
    background-color: #fff;
    border-radius: 5px;
    padding: 8px;
    margin-top: 15px;

    label {
      display: flex;
      width: 100%;
      align-items: center;

      .icon_left {
        height: 100%;
        display: flex;

        span {
          display: flex;
        }
      }

      .label_text {
        margin-left: 15px;
      }
    }

    .checkbox {
      & + label:before {
        position: absolute;
        right: 0;
      }
      &:checked + label:after {
        left: unset;
        right: 24px;
        top: 15px;
      }
    }
  }

  &.signup_form {
    margin: 15px auto;

    .checkbox {
      & + label {
        font-size: 12px;
        font-weight: normal;

        span {
          margin-left: 28px;
          display: block;
          margin-top: -24px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .checkbox_container {
    position: relative;

    .checkbox {
      & + label {
        font-size: $small-font-size;
      }

      & + label:before {
        width: 15px;
        height: 15px;
      }

      &:checked + label:after {
        left: 3px;
        top: 8px;
      }
    }

    &.social_icons_style_checkbox,
    &.profile_pic_border_style_checkbox {
      height: 50px;
    }

    &.image_link_style_checkbox {
      width: 100px;
      height: 55px;
      margin-top: 10px;

      .checkbox {
        &:checked + label:after {
          left: 20px;
        }
      }
    }

    &.checkbox_with_icon_on_left {
      .checkbox {
        &:checked + label:after {
          right: 22px;
        }
      }
    }
  }
}
