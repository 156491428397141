@import "../../assets/styles/variables.scss";

.umicard_viewer {
  width: 100%;
  background-color: #ffffff;
  min-height: 100%;
  background-attachment: fixed;

  .main_content {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    z-index: 1;
    padding: 20px 10px;

    .branding_background_overlay {
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.layout_1 {
    background-color: #f4f7ff;
  }
}
