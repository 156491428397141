@import "../../../../assets/styles/variables.scss";

.password_input_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161f36ba;
  backdrop-filter: blur(4px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  form {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    input {
      background-color: transparent !important;
      color: #ffffff !important;
    }

    button {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-radius: 4px;
    }

    input,
    button {
      height: 40px;
    }
  }
}
