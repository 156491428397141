:root {
  --body-text: #56595f;
  --heading-text: #354640;
  --primary: #1797ff;
  --secondary: #00d45b;
  --black: black;
  --light: #f1f8fc;
  --white: white;
  --primary-light: #d2f0ff;
  --dark-links: #505050;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

body.webflow {
  color: var(--body-text);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

h1 {
  color: var(--heading-text);
  margin-top: 0.5rem;
  margin-bottom: 1.625rem;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  color: var(--heading-text);
  letter-spacing: 0.01em;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 1.3;
}

h5 {
  color: var(--heading-text);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
}

.form-checkbox {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 0;
  display: flex;
}

.form-checkbox-icon {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
}

.form-checkbox-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  background-size: 90%;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
}

.form-checkbox-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
  box-shadow: 0 0 0.25rem 0 #3898ec;
}

.styleguide_classes {
  display: none;
}

.icon-large {
  height: 4rem;
}

.show-tablet {
  display: none;
}

.overflow-auto {
  overflow: auto;
}

.show {
  display: block;
}

.global-styles {
  display: block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.heading-small {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}

.styleguide_color-sample {
  background-color: #f5f5f5;
  margin-top: 0.5rem;
  padding: 5rem;
}

.styleguide_color-sample.is-primary {
  background-color: var(--primary);
}

.styleguide_color-sample.is-text-color {
  background-color: var(--body-text);
}

.styleguide_color-sample.is-secondary {
  background-color: var(--secondary);
}

.styleguide_color-sample.is-heading-color {
  background-color: var(--heading-text);
}

.text-weight-semibold {
  font-weight: 600;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.background-color-black {
  background-color: var(--black);
  color: #f5f5f5;
}

.styleguide_3-col {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-color-black {
  color: #000;
}

.styleguide_2-col {
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  justify-content: start;
  display: grid;
}

.text-color-grey {
  color: gray;
}

.form-message-success {
  padding: 1.25rem;
}

.container-small {
  width: 100%;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.styleguide_page-header {
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-weight-normal {
  font-weight: 400;
}

.button {
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.375rem;
  font-size: 0.9375rem;
  font-weight: 500;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

.button:hover {
  background-color: #1f8ce6;
}

.button.is-navbar {
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  line-height: 1;
}

.button.is-secondary {
  background-color: var(--secondary);
}

.button.is-secondary:hover {
  background-color: #26c36a;
}

.button.is-white {
  background-color: var(--secondary);
  color: #ca1c4f;
}

.button.is-white:hover {
  background-color: var(--light);
}

.max-width-small {
  width: 100%;
  max-width: 20rem;
}

.text-style-italic {
  font-style: italic;
}

.margin-top {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.button-secondary {
  color: #386eec;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0.125rem solid #386eec;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-weight: 600;
}

.text-weight-medium {
  margin-left: 0;
  font-weight: 500;
}

.overflow-hidden {
  overflow: hidden;
}

.text-size-tiny {
  font-size: 0.75rem;
}

.max-width-xxlarge {
  width: 100%;
  max-width: 80rem;
}

.icon-small {
  height: 2rem;
}

.background-color-grey {
  background-color: rgba(168, 193, 216, 0.2);
}

.heading-xsmall {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}

.styleguide_section-type {
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #000;
  padding: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
}

.text-weight-light {
  font-weight: 300;
}

.max-width-xsmall {
  width: 100%;
  max-width: 16rem;
}

.text-style-link {
  text-decoration: underline;
}

.text-size-regular {
  font-size: 1rem;
}

.text-weight-xbold {
  font-weight: 800;
}

.text-align-right {
  text-align: right;
}

.text-weight-bold {
  font-weight: 700;
}

.max-width-medium {
  width: 100%;
  max-width: 32rem;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.background-color-white {
  background-color: var(--white);
}

.text-style-muted {
  opacity: 0.6;
}

.text-size-small {
  font-size: 0.875rem;
}

.styleguide_section {
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
}

.max-width-xlarge {
  width: 100%;
  max-width: 64rem;
}

.form-radio-icon {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0.5rem;
}

.form-radio-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  border-width: 0.25rem;
}

.form-radio-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  box-shadow: 0 0 0.25rem 0 #3898ec;
}

.text-style-nowrap {
  white-space: nowrap;
}

.text-align-left {
  text-align: left;
}

.form-input {
  min-height: 3rem;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
}

.form-input.is-text-area {
  min-height: 8rem;
  padding-top: 0.75rem;
}

.text-size-large {
  font-size: 1.5rem;
}

.icon-1x1-small {
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page-wrapper {
  background-color: #f1f8fc;
}

.heading-large {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.container-large {
  width: 100%;
  max-width: 72.5rem;
  margin-left: auto;
  margin-right: auto;
}

.text-style-allcaps {
  text-transform: uppercase;
}

.overflow-scroll {
  overflow: scroll;
}

.icon-1x1-medium {
  width: 3rem;
  height: 3rem;
}

.show-mobile-landscape {
  display: none;
}

.form-message-error {
  margin-top: 0.75rem;
  padding: 0.75rem;
}

.icon-1x1-large {
  width: 4rem;
  height: 4rem;
}

.icon-medium {
  width: 2.5rem;
  height: 2.5rem;
}

.text-align-center {
  text-align: center;
}

.form-wrapper {
  margin-bottom: 0;
}

.max-width-xxsmall {
  width: 100%;
  max-width: 12rem;
}

.layer {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.styleguide_background-spacer {
  height: 5rem;
}

.text-style-quote {
  border-left: 0.25rem solid #e2e2e2;
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.styleguide_row {
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: start;
  display: grid;
}

.styleguide_row.is-for-social-icons {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
}

.styleguide_1-col {
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.styleguide_1-col.gap-medium {
  grid-row-gap: 2.5rem;
}

.show-mobile-portrait {
  display: none;
}

.page-padding {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.heading-xlarge {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

.text-size-medium {
  margin-left: 0;
  font-size: 1.4rem;
}

.button-text {
  color: var(--heading-text);
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.375rem;
  font-size: 0.9375rem;
  font-weight: 500;
  transition: color 0.25s ease-in-out;
}

.button-text:hover {
  color: var(--primary);
}

.button-text.is-navbar {
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  line-height: 1;
}

.hide {
  display: none;
}

.heading-medium {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.form-radio {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 0;
  display: flex;
}

.background-color-gradient {
  background-image: linear-gradient(#35c3ff, #037eff);
}

.background-color-gradient.is-clipped {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.section-hero {
  background-color: var(--light);
}

.section-hero.is-youtube {
  background-color: var(--white);
  color: var(--white);
  background-image: url("./images/videoGalleryBackground.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;
}

.navbar-container {
  width: 100%;
  max-width: 72.5rem;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navbar-menu {
  margin-left: auto;
  margin-right: auto;
}

.navbar-buttons-wrapper {
  align-items: center;
  display: flex;
}

.navbar-link {
  color: var(--heading-text);
  padding: 0.75rem 1.25rem;
  transition: color 0.25s ease-in-out;
}

.navbar-link:hover {
  color: var(--primary);
}

.navbar-link.w--current {
  color: var(--heading-text);
  letter-spacing: 0.05em;
  text-shadow: 1px 0 0 var(--heading-text);
}

.navbar-link.w--open {
  color: var(--primary);
}

.margin-right-1 {
  margin-right: 0.125rem;
}

.button-icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary-light);
  color: var(--primary);
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  display: flex;
}

.button-icon:hover {
  background-color: var(--primary);
  color: var(--white);
}

.button-icon.button-circle {
  border-radius: 2.5rem;
}

.navbar-wrapper {
  z-index: 1080;
  background-color: var(--light);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.section-spacing {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.section-spacing.gap-hero {
  padding-top: 5.75rem;
  padding-bottom: 8.875rem;
}

.section-spacing.gap-hero.is-youtube {
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.section-spacing.gap-community {
  padding-top: 5rem;
  padding-bottom: 8rem;
}

.section-spacing.gap-before-footer {
  padding-bottom: 8.5rem;
}

.hero-content {
  max-width: 40.25rem;
  margin-bottom: 18.75rem;
  position: relative;
}

.hero-content.is-youtube {
  margin-bottom: 3rem;
}

.text-color-primary {
  color: var(--primary);
}

.text-color-secondary {
  color: var(--secondary);
}

.hero-inner-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hero-mockup {
  position: relative;
}

.mockup-wrapper {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.mockup-wrapper.is-youtube {
  position: relative;
  left: 0;
  transform: none;
}

.section-feature {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 5vw;
}

.feature-image-wrapper {
  width: 100%;
  max-width: 35rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: perspective(200rem);
}

.feature-image-wrapper.is-switched._2 {
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 6rem;
}

.feature-image-wrapper.is-switched._4,
.feature-image-wrapper.is-switched._13 {
  justify-content: flex-end;
}

.feature-image-wrapper.is-switched._15 {
  justify-content: flex-end;
  align-items: flex-end;
}

.feature-image-wrapper._3 {
  max-width: 40rem;
  align-items: flex-start;
  margin-bottom: 6rem;
}

.feature-image-wrapper._7 {
  justify-content: flex-end;
}

.feature-image-wrapper._11 {
  margin-bottom: 4.5rem;
}

.feature-image-wrapper._12 {
  justify-content: flex-end;
  align-items: flex-end;
}

.feature-image-wrapper._16 {
  align-items: flex-end;
}

.feature-image-wrapper._1.alt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.layer-2 {
  z-index: 30;
  transform-style: preserve-3d;
  position: absolute;
  top: 60px;
  right: 26px;
  transform: translate3d(0, 0, 200px);
}

.layer-2._1.image {
  position: static;
}

.layer-2._2 {
  top: auto;
  bottom: 0;
  right: 4px;
}

.layer-2._3 {
  top: 136px;
  right: 10px;
}

.layer-2._4 {
  top: auto;
  bottom: 148px;
  right: 70px;
}

.layer-2._5 {
  top: 80px;
}

.layer-2._7 {
  top: -80px;
}

.layer-2._9 {
  top: 120px;
}

.layer-2._10 {
  z-index: 50;
  top: auto;
  bottom: -30px;
  right: 10px;
}

.layer-2._11 {
  z-index: 50;
  top: 80px;
}

.layer-2._12 {
  top: 20px;
  left: 100px;
  right: auto;
}

.layer-2._13 {
  right: 0;
}

.layer-2._16 {
  top: auto;
  bottom: 0;
  left: -70px;
  right: auto;
}

.layer-4 {
  z-index: -1;
  transform-style: preserve-3d;
  position: relative;
  transform: translate3d(0, 0, 40px);
}

.layer-4._1 {
  width: 100%;
  max-width: 430px;
}

.layer-4._2 {
  width: 100%;
  max-width: 560px;
}

.layer-4._3 {
  width: 100%;
  max-width: 502px;
}

.layer-4._11 {
  width: 100%;
  max-width: 510px;
}

.layer-4._12 {
  width: 100%;
  max-width: 455px;
}

.layer-4._13,
.layer-4._14 {
  width: 100%;
  max-width: 560px;
}

.layer-4._16 {
  width: 100%;
  max-width: 430px;
}

.layer-3 {
  transform-style: preserve-3d;
  position: absolute;
  transform: translate3d(0, 0, 110px);
}

.layer-3._1.image {
  position: static;
}

.layer-3._2 {
  left: 40px;
}

.layer-3._4 {
  margin-left: -60px;
}

.layer-3._7 {
  margin-bottom: 20px;
  margin-right: -96px;
}

.layer-3._12 {
  right: 10px;
}

.layer-3._13 {
  left: 10px;
}

.layer-3._15 {
  right: 34px;
}

.layer-3._16 {
  right: 90px;
}

.layer-1 {
  z-index: 40;
  transform-style: preserve-3d;
  position: absolute;
  bottom: 14px;
  left: -32px;
  transform: translate3d(0, 0, 160px);
}

.layer-1._1 {
  bottom: 20px;
  left: -40px;
}

.layer-1._1.image {
  position: static;
}

.layer-1._2 {
  bottom: 110px;
  left: 6px;
}

.layer-1._6 {
  bottom: 0;
}

.layer-1._9 {
  top: 60px;
  bottom: auto;
}

.layer-1._10 {
  top: 0;
  bottom: auto;
}

.layer-1._11 {
  top: 44px;
  bottom: auto;
  left: auto;
  right: 100px;
}

.layer-1._12 {
  left: -112px;
}

.layer-1._13 {
  bottom: 110px;
  left: 0;
}

.layer-1._16 {
  top: 20px;
  bottom: auto;
  left: -10px;
}

.grid-halves {
  grid-column-gap: 0.75rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.feature-content {
  max-width: 28.75rem;
}

.mb-xlarge {
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 2rem;
  display: grid;
}

.footer {
  background-color: #f8f8f9;
}

.footer-logo-wrapper {
  margin-bottom: 4.375rem;
}

.footer-main {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.footer-copyrights {
  background-color: var(--primary);
  color: var(--white);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.footer-links {
  justify-content: space-between;
  display: flex;
}

.footer-links-column {
  width: 100%;
  max-width: 11.25rem;
}

.footer-links-column-heading {
  margin-bottom: 1.25rem;
}

.footer-link {
  color: var(--dark-links);
  margin-bottom: 0.875rem;
  font-size: 0.9375rem;
  transition: color 0.25s ease-in-out;
  display: block;
}

.footer-link:hover {
  color: var(--primary);
}

.social-links {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.social-link {
  transition: filter 0.25s ease-in-out;
}

.social-link:hover {
  filter: brightness(85%);
}

.image-platforms {
  margin-top: 0.5rem;
  transform: translate(-2.625rem);
}

.community-content {
  margin-bottom: 3.5rem;
}

.grid-community {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.card {
  flex-direction: column;
  display: flex;
}

.card-content {
  text-align: center;
  margin-bottom: 1.375rem;
  font-size: 1.0625rem;
  font-weight: 700;
}

.card-image {
  width: 100%;
  border-radius: 0.625rem;
  flex: none;
}

.text-color-white {
  color: var(--white);
}

.hero-floating-icons {
  height: 18.75rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.badge {
  position: absolute;
}

.badge._01 {
  top: 16%;
  left: 5%;
}

.badge._02 {
  top: 50%;
  left: -5%;
}

.badge._03 {
  top: 47%;
  left: 16%;
}

.badge._04 {
  top: 41%;
  left: 28%;
}

.badge._05 {
  bottom: 15%;
  left: 44%;
}

.badge._06 {
  bottom: 35%;
  right: 28%;
}

.badge._07 {
  bottom: 10%;
  right: 28%;
}

.badge._08 {
  top: 12%;
  right: 16%;
}

.badge._09 {
  bottom: 25%;
  right: 0%;
}

.badge._10 {
  top: 25%;
  right: -10%;
}

.social {
  position: absolute;
}

.social._01 {
  top: 38%;
  left: 6%;
}

.social._02 {
  top: 70%;
  left: 10%;
}

.social._03 {
  top: 66%;
  left: 22%;
}

.social._04 {
  top: 32%;
  left: 26%;
}

.social._05 {
  top: 50%;
  left: 38%;
}

.social._06 {
  bottom: 15%;
  left: 40%;
}

.social._07 {
  bottom: 28%;
  right: 42%;
}

.social._09 {
  bottom: 10%;
  right: 26%;
}

.social._08 {
  top: 41%;
  right: 35%;
}

.social._10 {
  bottom: 45%;
  right: 20%;
}

.social._11 {
  bottom: 30%;
  right: 12%;
}

.social._12 {
  top: 28%;
  right: 12%;
}

.social._13 {
  top: 45%;
  right: -2%;
}

.typed {
  margin-top: -1.125rem;
}

.typed-wrapper {
  height: 5.625rem;
}

.hero-floating-overlay {
  background-image: linear-gradient(
    #f1f8fc,
    rgba(241, 248, 252, 0.37) 62%,
    rgba(241, 248, 252, 0)
  );
  position: absolute;
  top: 10px;
  bottom: 0;
  left: -50px;
  right: -80px;
}

.layer-full,
.navbar-link-icon {
  display: none;
}

.navbar-dropdown-menu.w--open {
  min-width: 11rem;
  background-color: var(--white);
  border-radius: 0.375rem;
  margin-left: 1.125rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}

.navbar-dropdown-menu.alt {
  margin-left: 0;
}

.dropdown-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.9375rem;
  transition: color 0.25s ease-in-out;
}

.dropdown-link:hover {
  color: var(--primary);
}

.button-icon-2 {
  width: 2.5rem;
  height: 2.5rem;
  color: #1797ff;
  background-color: #d2f0ff;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  display: flex;
}

.button-icon-2:hover {
  color: #fff;
  background-color: #1797ff;
}

.button-icon-2.button-circle {
  border-radius: 2.5rem;
  justify-content: center;
  padding: 0;
}

.feature-images-wrapper {
  position: relative;
}

.slider {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 3rem;
}

.slide-image {
  width: 100%;
  display: block;
}

.section {
  width: 1440px;
  height: 969px;
  background-color: rgba(0, 0, 0, 0);
}

.heading {
  text-align: left;
  margin-left: 10vw;
  font-size: 1.8rem;
}

.button-2 {
  padding-left: 1.35rem;
  padding-right: 1.35rem;
}

.button-3 {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: block;
  overflow: visible;
}

.button-4 {
  background-image: linear-gradient(270deg, #25d366, #2dbcff);
  border: 2px solid #f92d2d;
  border-radius: 3.1rem;
  align-self: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
  margin-left: 5vw;
  padding-top: 0.5rem;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  font-size: 1.3rem;
}

.bold-text {
  font-size: 2.3rem;
}

.text-span {
  font-size: 2.2rem;
}

.image-2 {
  align-self: center;
  margin-left: 5vw;
}

.section_basicpricing {
  width: 100vw;
  background-color: #f1f8fc;
  display: block;
}

.section-2 {
  width: 100vw;
  background-color: #f1f8fc;
  margin-bottom: 50px;
}

.text-block {
  color: #25d366;
  margin-top: 30px;
  margin-left: 51px;
  font-size: 1.4rem;
  font-weight: 600;
}

.section-3 {
  background-color: #f1f8fc;
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading-2 {
  text-align: center;
  margin-top: 5vw;
  margin-bottom: 5vw;
  padding: 5vw;
}

.section-4 {
  background-color: #f1f8fc;
  justify-content: center;
  align-items: center;
  margin-top: 10vw;
  margin-bottom: 10vw;
  margin-left: 5vw;
  padding-right: 5vw;
  display: flex;
}

.container {
  background-color: #f1f8fc;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
}

.heading-3 {
  width: 50vw;
  margin-left: 5vw;
  font-size: 2.3rem;
}

.image-3 {
  margin-top: 2vw;
  margin-bottom: 2vw;
  display: inline-block;
}

.text-span-2 {
  color: var(--secondary);
}

.text-span-3 {
  color: var(--primary);
}

.heading-4 {
  display: block;
}

.section-5 {
  justify-content: center;
  align-items: center;
  display: block;
}

.div-block {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-2 {
  justify-content: center;
  align-items: center;
  display: block;
}

.heading-5 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dropdown {
  justify-content: center;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
}

.accordion {
  height: 80px;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
}

.dropdown-toggle {
  display: block;
}

.faq-plus {
  width: 100%;
  height: 5px;
  background-color: #2aa8ff;
  border-radius: 3px;
}

.faq-answer-text {
  margin-bottom: 30px;
  font-size: 16px;
}

.link {
  color: #2aa8ff;
  font-weight: 600;
}

.faq-plus-l {
  width: 5px;
  height: 100%;
  background-color: #2aa8ff;
  border-radius: 3px;
  position: absolute;
}

.faq-question {
  height: 90px;
  cursor: pointer;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  display: flex;
}

.full-texture {
  z-index: 0;
  opacity: 0.2;
  background-image: url("./images/wavy.svg"),
    linear-gradient(to top, rgba(0, 0, 0, 0), #fff), url("./images/bg-line.svg"),
    url("./images/Path-5.svg");
  background-position: 50% 101%, 0 0, 0 0, 100%;
  background-repeat: no-repeat, repeat, repeat, repeat;
  background-size: contain, auto, 10px, 6000px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.container-2 {
  z-index: 2;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5vw;
  padding-right: 5vw;
  display: block;
  position: relative;
}

.container-2.faq-container {
  max-width: 900px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding-top: 60px;
  padding-bottom: 80px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.06);
}

.faq-plus-wrap {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  display: flex;
  position: relative;
}

.pricing-h1 {
  text-align: center;
}

.pricing-h1.faq-h2 {
  margin-bottom: 50px;
}

.faq-section {
  padding-top: 50px;
  padding-bottom: 200px;
  position: relative;
}

.faq-answer {
  border-bottom: 1px solid rgba(45, 62, 80, 0.12);
  padding-left: 45px;
  overflow: hidden;
}

.faq-answer.last-faq-answer {
  border-bottom-width: 0;
}

.section-6 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.container-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(248, 248, 248, 0);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  display: flex;
}

.vectors-wrapper {
  width: 100px;
  height: 100px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.section-7 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
}

.container-4 {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(248, 248, 248, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
}

.text {
  color: #000;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 160%;
}

.section-8 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(255, 255, 255, 0);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
}

.vectors-wrapper-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.introvideo {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.small-container {
  width: 100%;
  max-width: 960px;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.title-section {
  width: 100%;
  min-width: 1px;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-2 {
  font-size: 1.4rem;
}

.text-3 {
  color: #212121;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
}

.image-wrapper {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.image-4 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.let-check-image-gallery-in-action-0 {
  color: #000;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.let-check-image-gallery-in-action-1 {
  color: #1ba8f0;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.let-check-image-gallery-in-action-3 {
  color: #25d366;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
}

.heading-6 {
  font-size: 2.5rem;
}

.heading-7 {
  font-size: 2.8rem;
}

.text-span-4 {
  color: var(--primary);
}

.text-span-5 {
  color: var(--secondary);
}

.ctaundervideo {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.small-container-2 {
  width: 100%;
  max-width: 960px;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: rgba(245, 247, 250, 0);
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  display: flex;
}

.content {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.vectors-wrapper-3 {
  height: 76.9502px;
  max-width: 100%;
  min-width: 20vw;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

._3-columns {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.column {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #f8f8f8;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.frame-101 {
  width: 100%;
  height: 88px;
  max-width: 425px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-image: linear-gradient(257.77deg, #25d366 0%, #2dbcff 100%);
  border: 1px solid #25d366;
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  padding: 20px 46px;
  display: flex;
}

.column-2 {
  width: 129px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: rgba(248, 248, 248, 0);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 24px;
  display: flex;
}

.text-5 {
  color: #000;
  text-align: center;
  letter-spacing: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 120%;
}

.get-your-umicard-now-0 {
  color: #000;
  text-align: center;
  letter-spacing: 0;
  font-size: 5vw;
  font-weight: 700;
}

.get-your-umicard-now-1 {
  color: #1ba8f0;
  text-align: center;
  letter-spacing: 0;
  font-size: 5vw;
  font-weight: 700;
  line-height: 120%;
}

.get-your-umicard-now-2 {
  color: #25d366;
  text-align: center;
  letter-spacing: 0;
  font-size: 5vw;
  font-weight: 700;
  line-height: 120%;
}

._295-month-basic-plan-0 {
  color: #fff;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 160%;
}

._295-month-basic-plan-1 {
  color: #fff;
  text-align: center;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 160%;
}

.fullycustomizablesection {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  align-items: flex-start;
  padding: 64px 24px;
  display: flex;
}

.container-5 {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.column-3 {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-12 {
  width: 100%;
  max-width: 460px;
  grid-column-gap: 33px;
  grid-row-gap: 33px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-15 {
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.text-6 {
  color: #354640;
  letter-spacing: 0.01em;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 130%;
}

.text-7 {
  color: #56595f;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
}

.frame-134 {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-132 {
  width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-112 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}

.vectors-wrapper-4 {
  width: 22px;
  height: 22px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-5 {
  height: 152.95px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-left: 2vw;
  display: flex;
}

.column-4 {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.fullycustomizablesection-2 {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  align-items: flex-start;
  padding: 64px 24px;
  display: flex;
}

.container-6 {
  width: 100%;
  max-width: 1200px;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.column-5 {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-135 {
  width: 100%;
  max-width: 460px;
  grid-column-gap: 33px;
  grid-row-gap: 33px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-136 {
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.text-8 {
  color: #354640;
  letter-spacing: 0.01em;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 130%;
}

.text-9 {
  color: #56595f;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
}

.frame-137 {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -40px;
  display: flex;
}

.frame-138 {
  width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-139 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1vw;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}

.vectors-wrapper-6 {
  width: 22px;
  height: 22px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-7 {
  height: 152.95px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.column-6 {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.vectors-wrapper-8 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-9 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.spacingdashline {
  text-align: center;
}

.calltoaction {
  width: 100%;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  background-color: #fff;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 24px;
  display: flex;
}

.container-7 {
  width: 100%;
  height: 749px;
  max-width: 1234px;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.title-copy-goes-here-be-awesome {
  color: #000;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 120%;
}

.lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique-duis-cursus-mi-quis-viverra-ornare-eros-dolor-interdum-nulla-ut-commodo-diam-libero-vitae-erat {
  color: #212121;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

.vectors-wrapper-9 {
  width: 22px;
  height: 22px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.text-10 {
  color: #56595f;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
}

.text-11 {
  color: #000;
  letter-spacing: 0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

.image-wrapper-2 {
  width: 100%;
  height: 562px;
  max-width: 598px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.list {
  padding-left: 5px;
  list-style-type: square;
}

.grid {
  grid-column-gap: 0px;
  grid-template-columns: minmax(50px, 0.25fr) 1fr;
  grid-template-areas: "Area";
}

.div-block-3 {
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 0.25fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: start;
  display: grid;
}

.div-block-4 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.text-block-2 {
  order: 1;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  padding-left: 8px;
  font-size: 1.3rem;
  display: flex;
}

.image-5 {
  align-self: center;
}

.text-block-3 {
  margin-top: 8px;
  padding-left: 8px;
  font-size: 1.3rem;
}

.div-block-5 {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 5vw;
  display: flex;
}

.heading-8 {
  color: var(--secondary);
  font-size: 2rem;
  font-weight: 500;
}

.grid-2 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: ". Area";
}

.heading-9,
.heading-10 {
  font-size: 1.5rem;
  font-weight: 400;
}

.grid-3 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 5vw;
}

.heading-11 {
  font-size: 1.5rem;
  font-weight: 500;
}

.heading-12,
.heading-13,
.heading-14 {
  font-size: 1.5rem;
  font-weight: 400;
}

.image-6 {
  border-radius: 5px;
}

.heading-15 {
  color: var(--primary);
}

.heading-16 {
  margin-top: 3vw;
  font-size: 2.1rem;
}

.div-block-6 {
  width: 30%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.div-block-7 {
  justify-content: center;
  display: flex;
}

.heading-17 {
  flex-direction: row;
  display: flex;
}

.heading-18 {
  font-size: 2rem;
  font-weight: 400;
}

.video {
  width: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-8 {
  justify-content: center;
  display: flex;
}

.heading-19 {
  margin-top: 2rem;
  font-size: 2.5rem;
}

.grid-4 {
  grid-column-gap: 21px;
  grid-template-rows: auto;
  grid-template-columns: minmax(200px, 4fr) 1fr minmax(200px, 4fr);
}

.heading-20 {
  font-family: Great Vibes, cursive;
}

.text-span-6 {
  color: var(--primary);
}

.text-span-7 {
  color: var(--secondary);
}

.heading-21 {
  margin-top: 4rem;
}

.text-span-8 {
  color: var(--primary);
}

.text-span-9 {
  color: var(--secondary);
}

.is-hiddendesktop,
.heading-22.ismobileonly,
.hidden-desktop,
.grid-7,
.grid-7.is-hiddendesktop {
  display: none;
}

.div-block-10 {
  margin-top: 8px;
  display: flex;
}

.text-block-4 {
  margin-left: 7px;
  font-size: 1.3rem;
}

.text-block-5 {
  margin-left: 8px;
}

.div-block-11 {
  margin-top: 8px;
  display: flex;
}

.grid-8 {
  grid-template-rows: auto;
}

.columns {
  width: 100%;
  max-width: 606px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.f2wf-columns-3 {
  text-align: left;
  max-width: 860px;
  display: flex;
  align-items: start;
  gap: 20px;
}

.column-7 {
  width: 100%;
  height: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: rgba(247, 251, 253, 1);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  box-shadow: 0px 24px 60px 0px rgba(25, 43, 67, 0.1);
}

.intro {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.pricing-left {
  margin-bottom: 24px;
}

.image-wrapper-3 {
  width: 80px;
  height: 80px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pricing-1 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.name {
  text-align: left;
  font-weight: bold;
}

.text-12 {
  color: #212121;
  letter-spacing: 0;
  font-size: 40px;
  font-weight: bold;
  line-height: 180%;
}

.pricing {
  text-align: left;
}

._599-mo {
  color: #333;
  text-align: left;
  letter-spacing: 0;
  font-size: 24px;
  font-weight: normal;
  line-height: 100%;
}

.button-5 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  background-color: rgba(23, 151, 255, 1);
  border: none;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  text-decoration: none;
  display: flex;
}

.text-13 {
  color: #fff;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
}

.separator {
  width: 100%;
  height: 1px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
}

.features {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.pricing-feature {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.icon {
  width: 24px;
  height: 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feature-item {
  color: #333;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: 90%;
}

.column-8 {
  width: 100%;
  height: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: rgba(247, 251, 253, 1);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  box-shadow: 0px 24px 60px 0px rgba(25, 43, 67, 0.1);
}

.button-6 {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(23, 151, 255, 1);
  border: none;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  text-decoration: none;
  display: flex;
}

.text-14 {
  color: #fff;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
}

.description {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding: 0 24px;
}

.text-15 {
  color: #212121;
  text-align: center;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
}

.features-2 {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.pricing-feature-2 {
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.icon-wrapper-2 {
  width: 24px;
  height: 24px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-10 {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-bottom: 50px;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.875rem;
  }

  .show-tablet {
    display: block;
  }

  .hide-tablet {
    display: none;
  }

  .max-width-full-tablet {
    width: 100%;
    max-width: none;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .styleguide_section {
    grid-column-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .text-size-medium {
    font-size: 1rem;
  }

  .navbar {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-menu {
    background-color: var(--light);
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 14px -2px rgba(0, 0, 0, 0.12);
  }

  .navbar-link {
    display: block;
  }

  .section-spacing.gap-hero {
    overflow: hidden;
  }

  .section-spacing.gap-community {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .section-spacing.gap-before-footer {
    padding-bottom: 5.5rem;
  }

  .feature-image-wrapper {
    max-width: 75%;
    align-items: flex-start;
  }

  .feature-image-wrapper.is-switched {
    margin-left: auto;
    margin-right: auto;
  }

  .feature-image-wrapper.is-switched._2 {
    margin-top: 0;
  }

  .feature-image-wrapper._3 {
    margin-bottom: 0;
  }

  .feature-image-wrapper._11 {
    margin-bottom: 0;
  }

  .feature-image-wrapper._1 {
    margin-left: auto;
    margin-right: auto;
  }

  .layer-2,
  .layer-4,
  .layer-3,
  .layer-1 {
    display: none;
  }

  .grid-halves {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .feature-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-main {
    padding-bottom: 0;
  }

  .footer-links {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .footer-links-column {
    max-width: 33%;
    margin-bottom: 3rem;
  }

  .image-platforms {
    transform: none;
  }

  .grid-community {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    grid-template-columns: 1fr 1fr;
  }

  .card {
    justify-content: space-between;
  }

  .navbar-menu-button {
    margin-right: 8px;
    padding: 6px 6px 6px 0;
  }

  .navbar-menu-button.w--open {
    color: var(--primary);
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-logo-wrapper {
    align-items: center;
    margin-right: auto;
    display: flex;
  }

  .navbar-menu-icon {
    font-size: 2.125rem;
  }

  .layer-full {
    display: block;
  }

  .layer-full._1 {
    margin-left: -22px;
  }

  .layer-full._3 {
    margin-top: -24px;
  }

  .layer-full._4 {
    margin-left: -40px;
  }

  .layer-full._5 {
    margin-top: -32px;
    margin-left: -19px;
  }

  .layer-full._9 {
    margin-left: -21px;
  }

  .layer-full._10 {
    margin-bottom: -32px;
  }

  .layer-full._12 {
    transform: translate(-20px);
  }

  .navbar-dropdown {
    display: block;
  }

  .navbar-dropdown-menu.w--open {
    margin-right: 1.125rem;
  }

  .button-4 {
    text-align: center;
  }

  .heading-7 {
    font-size: 2rem;
  }

  .f2wf-columns,
  .f2wf-columns-2 {
    flex-direction: column;
    align-items: center;
  }

  .grid-6 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 0.25fr;
    grid-auto-flow: column;
  }

  .heading-22 {
    font-size: 1.9rem;
  }

  .div-block-9 {
    text-align: center;
  }

  .f2wf-columns-3 {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.25rem;
  }

  .heading-small {
    font-size: 1rem;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .styleguide_color-sample {
    padding: 2.5rem 2rem;
  }

  .styleguide_3-col {
    grid-template-columns: 1fr 1fr;
  }

  .styleguide_page-header {
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .button.is-navbar {
    font-size: 0.9375rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .styleguide_section-type {
    font-size: 0.875rem;
  }

  .max-width-full-mobile-landscape {
    width: 100%;
    max-width: none;
  }

  .text-style-nowrap {
    white-space: normal;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .heading-large {
    font-size: 2rem;
  }

  .show-mobile-landscape {
    display: block;
  }

  .styleguide_row.is-for-social-icons {
    grid-template-rows: auto auto;
  }

  .page-padding {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .heading-xlarge {
    font-size: 2.5rem;
  }

  .button-text.is-navbar {
    font-size: 0.9375rem;
  }

  .heading-medium {
    font-size: 1.5rem;
  }

  .navbar {
    padding-bottom: 1.125rem;
  }

  .navbar-logo {
    max-width: 8rem;
    padding-left: 0;
  }

  .button-icon.button-circle {
    width: 2.375rem;
    height: 2.375rem;
  }

  .image-platforms.is-youtube {
    margin-bottom: -3rem;
  }

  .typed-wrapper {
    height: 4.25rem;
  }

  .navbar-menu-button {
    padding-right: 0;
  }

  .navbar-menu-icon {
    font-size: 2rem;
  }

  .layer-full._3 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .layer-full._4 {
    margin-left: -24px;
  }

  .pricing-h1 {
    font-size: 34px;
    line-height: 44px;
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr;
    display: none;
  }

  .heading-27,
  .heading-28,
  .heading-29,
  .heading-30 {
    font-size: 22px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.4375rem;
  }

  .styleguide_2-col {
    grid-template-columns: auto;
  }

  .max-width-full-mobile-portrait {
    width: 100%;
    max-width: none;
  }

  .button {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.8125rem;
  }

  .button.is-navbar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .hide-mobile-portrait {
    display: none;
  }

  .text-size-large {
    font-size: 1.1rem;
  }

  .show-mobile-portrait {
    display: block;
  }

  .page-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .text-size-medium {
    text-align: left;
    margin-bottom: 5vw;
    font-size: 1.1rem;
  }

  .navbar-logo {
    max-width: 7.5rem;
    flex: none;
  }

  .section-spacing.gap-hero {
    padding-top: 4rem;
  }

  .section-spacing.gap-hero.is-youtube {
    padding-bottom: 2rem;
  }

  .feature-image-wrapper,
  .feature-image-wrapper._12 {
    max-width: 100%;
  }

  .grid-halves {
    grid-template-rows: auto auto auto;
  }

  .mb-xlarge {
    grid-template-columns: 4.5fr 0.25fr;
    margin-bottom: 1.25rem;
  }

  .footer-main {
    text-align: center;
  }

  .footer-copyrights {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: 0.75rem;
  }

  .footer-links-column {
    max-width: 100%;
  }

  .social-links {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .grid-community {
    grid-template-columns: 1fr;
  }

  .card-content {
    margin-bottom: 1.25rem;
    font-size: 0.9375rem;
  }

  .text-color-white {
    margin-top: 5vw;
    margin-bottom: -2.4rem;
    font-size: 1.2rem;
  }

  .badge._05 {
    left: 35%;
  }

  .social._05 {
    top: 37%;
  }

  .social._06 {
    left: 30%;
  }

  .social._11 {
    bottom: 39%;
  }

  .hero-floating-overlay {
    display: none;
  }

  .navbar-menu-button {
    margin-left: -4px;
    padding-left: 0;
  }

  .layer-full._12 {
    transform: none;
  }

  .heading {
    font-size: 1.4rem;
  }

  .button-4 {
    text-align: center;
    margin-top: 7vw;
    margin-left: 10vw;
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
    display: block;
  }

  .bold-text {
    font-size: 1.8rem;
  }

  .text-span {
    font-size: 1.3rem;
  }

  .image-2 {
    font-size: 1rem;
    line-height: 1;
  }

  .text-block {
    margin-top: 10px;
    font-size: 0.9rem;
  }

  .heading-2 {
    margin-top: 5vw;
    margin-bottom: 5vw;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 5vw;
  }

  .image-3 {
    display: none;
  }

  .heading-7 {
    text-align: center;
    font-size: 1.3rem;
  }

  .text-9 {
    min-width: 0.7rem;
    font-size: 0.6rem;
  }

  .vectors-wrapper-6 {
    width: 1.3rem;
  }

  .text-block-3 {
    text-align: left;
    flex: 0 auto;
    align-self: flex-start;
    font-size: 1rem;
  }

  .div-block-5 {
    font-size: 0.8rem;
  }

  .heading-8 {
    font-size: 1.2rem;
  }

  .grid-3 {
    grid-template-rows: auto auto auto auto;
    grid-auto-flow: row;
    display: none;
  }

  .heading-11 {
    display: none;
  }

  .heading-15 {
    font-size: 1.4rem;
  }

  .heading-16 {
    font-size: 1.2rem;
  }

  .div-block-7 {
    align-items: center;
    font-size: 0.6rem;
    display: block;
  }

  .heading-18 {
    text-align: center;
    margin-top: 0.125rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }

  .video {
    padding-bottom: 0.125rem;
  }

  .heading-19 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  .grid-4 {
    grid-column-gap: 5px;
    grid-template-rows: auto auto;
    grid-template-areas:
      ". . Area"
      ". . .";
    grid-auto-flow: row;
  }

  .heading-20 {
    text-align: center;
  }

  .heading-21 {
    text-align: center;
    align-self: center;
    margin-top: 0.125rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  .is-hiddenmobile {
    display: none;
  }

  .grid-5 {
    grid-column-gap: 0px;
    grid-template-rows: auto auto auto;
    grid-template-columns: minmax(200px, 1.75fr);
    grid-auto-columns: 0.25fr;
    display: grid;
  }

  .grid-6 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: auto minmax(57px, 0.25fr);
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .heading-22 {
    text-align: center;
  }

  .heading-23,
  .heading-24 {
    text-align: left;
  }

  .image-7 {
    max-width: 80%;
    margin-top: 10px;
  }

  .image-8 {
    max-width: 80%;
  }

  .heading-25 {
    margin-top: 10px;
    font-size: 1.5rem;
  }

  .heading-26 {
    margin-top: 10px;
  }

  .grid-7 {
    grid-template-rows: auto auto auto auto auto auto auto auto;
    grid-template-columns: 1fr;
    display: block;
  }

  .grid-7.is-hiddendesktop {
    display: none;
  }

  .heading-27,
  .heading-28,
  .heading-29,
  .heading-30 {
    font-size: 20px;
    font-weight: 400;
  }

  .text-block-4 {
    text-align: left;
    font-size: 1rem;
  }
}

#w-node-_8a383b0e-1243-2f61-454e-370cfaa3f756-156ff136,
#w-node-_6f4e3ed8-3f71-00dd-274c-4ec074fc2b1b-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_16585bea-bac4-5cc4-631c-9a13981ccbc4-156ff136 {
  justify-self: end;
}

#w-node-_976a2096-87e1-b17b-c5c0-b4ca5465b8f8-156ff136 {
  justify-self: start;
}

#w-node-_976a2096-87e1-b17b-c5c0-b4ca5465b8ef-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_44a6d3a9-73a1-e482-3b7e-6a1e8aecd88a-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_44a6d3a9-73a1-e482-3b7e-6a1e8aecd893-156ff136 {
  justify-self: end;
}

#w-node-_832258a5-3ef6-85d7-7501-c718fcf69911-156ff136 {
  justify-self: start;
}

#w-node-_832258a5-3ef6-85d7-7501-c718fcf69908-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a8ba4b2b-2265-1878-55ad-9d0f866719bb-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a8ba4b2b-2265-1878-55ad-9d0f866719c4-156ff136 {
  justify-self: end;
}

#w-node-_498b9957-9f73-e65f-d909-a726122f6810-156ff136 {
  justify-self: start;
}

#w-node-_498b9957-9f73-e65f-d909-a726122f6807-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_998b9630-aced-1b56-cdd2-c0a9f299a34f-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_998b9630-aced-1b56-cdd2-c0a9f299a358-156ff136 {
  justify-self: end;
}

#w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba576-156ff136 {
  justify-self: start;
}

#w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba57b-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f7efd667-51ee-cb60-2644-76485ab3fcf2-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f7efd667-51ee-cb60-2644-76485ab3fd01-156ff136 {
  justify-self: end;
}

#w-node-_543d9649-a434-2581-1cf0-272ce13f57e1-156ff136 {
  justify-self: start;
}

#w-node-_543d9649-a434-2581-1cf0-272ce13f57e6-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_71299335-cd61-60a4-94e0-c2cdbdc61ba7-156ff136 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_71299335-cd61-60a4-94e0-c2cdbdc61bb6-156ff136 {
  justify-self: end;
}

#w-node-ffce7ee7-6783-3c0d-88e9-79a630fa002f-156ff136,
#w-node-e098b266-7b21-e99b-3c50-34de7c0285d5-156ff136,
#w-node-_6acc3555-16f3-8995-cc43-8b453a1adb97-156ff136,
#w-node-d486c00e-c718-bc78-d0c6-2ba91ce712ec-156ff136,
#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f24-316ff138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f2d-316ff138 {
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f37-316ff138 {
  justify-self: start;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f3c-316ff138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f4c-316ff138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f55-316ff138 {
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f5f-316ff138 {
  justify-self: start;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-316ff138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f74-316ff138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f83-316ff138 {
  justify-self: end;
}

#w-node-_71e3b27e-c565-77f8-821c-26bfb01f0c5f-8a66a138,
#w-node-_60e49964-9e7b-e868-1028-2991dfe23aba-8a66a138,
#w-node-_16ed6074-db2a-e44e-166f-fff4a0cb6275-8a66a138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_16ed6074-db2a-e44e-166f-fff4a0cb6276-8a66a138 {
  justify-self: end;
}

#w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980244-8a66a138 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980245-8a66a138 {
  justify-self: end;
}

#w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980234-8a66a138,
#w-node-_8a383b0e-1243-2f61-454e-370cfaa3f756-d4b5fde4,
#w-node-_6f4e3ed8-3f71-00dd-274c-4ec074fc2b1b-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_16585bea-bac4-5cc4-631c-9a13981ccbc4-d4b5fde4 {
  justify-self: end;
}

#w-node-_832258a5-3ef6-85d7-7501-c718fcf69911-d4b5fde4 {
  justify-self: start;
}

#w-node-_832258a5-3ef6-85d7-7501-c718fcf69908-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a8ba4b2b-2265-1878-55ad-9d0f866719bb-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a8ba4b2b-2265-1878-55ad-9d0f866719c4-d4b5fde4 {
  justify-self: end;
}

#w-node-_498b9957-9f73-e65f-d909-a726122f6810-d4b5fde4 {
  justify-self: start;
}

#w-node-_498b9957-9f73-e65f-d909-a726122f6807-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_998b9630-aced-1b56-cdd2-c0a9f299a34f-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_998b9630-aced-1b56-cdd2-c0a9f299a358-d4b5fde4 {
  justify-self: end;
}

#w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba576-d4b5fde4 {
  justify-self: start;
}

#w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba57b-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f7efd667-51ee-cb60-2644-76485ab3fcf2-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f7efd667-51ee-cb60-2644-76485ab3fd01-d4b5fde4 {
  justify-self: end;
}

#w-node-_543d9649-a434-2581-1cf0-272ce13f57e1-d4b5fde4 {
  justify-self: start;
}

#w-node-_543d9649-a434-2581-1cf0-272ce13f57e6-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_71299335-cd61-60a4-94e0-c2cdbdc61ba7-d4b5fde4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_71299335-cd61-60a4-94e0-c2cdbdc61bb6-d4b5fde4 {
  justify-self: end;
}

#w-node-ffce7ee7-6783-3c0d-88e9-79a630fa002f-d4b5fde4,
#w-node-e098b266-7b21-e99b-3c50-34de7c0285d5-d4b5fde4,
#w-node-_6acc3555-16f3-8995-cc43-8b453a1adb97-d4b5fde4,
#w-node-d486c00e-c718-bc78-d0c6-2ba91ce712ec-d4b5fde4,
#w-node-_70c45516-226c-9bb1-7739-bf1ba48638ca-a48638c5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_70c45516-226c-9bb1-7739-bf1ba48638d3-a48638c5 {
  justify-self: end;
}

#w-node-_3cce74df-0682-2b98-86f3-62b9db8e1ae8-db8e1ae3 {
  justify-self: start;
}

#w-node-_3cce74df-0682-2b98-86f3-62b9db8e1af6-db8e1ae3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a775d9ba-5c98-02bf-3bd1-802961e621aa-a268b739,
#w-node-_3165de4a-6a0b-9bef-43ab-f623c7333d11-a268b739,
#w-node-_902b6619-05ed-f6c9-fb46-65344fb3359d-a268b739,
#w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad32f-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad34c-a268b739 {
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f5f-a268b739 {
  justify-self: start;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ac78ca84-8796-2e96-c652-fd50203bf9ff-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ac78ca84-8796-2e96-c652-fd50203bfa1c-a268b739 {
  justify-self: end;
}

#w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b6-a268b739 {
  justify-self: start;
}

#w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b8-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_52be971d-cfd5-8815-4291-eb87b20766dd-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_52be971d-cfd5-8815-4291-eb87b20766eb-a268b739 {
  justify-self: end;
}

#w-node-_92e84895-938d-b5ec-c691-8716ff367d06-a268b739 {
  justify-self: start;
}

#w-node-_92e84895-938d-b5ec-c691-8716ff367d08-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_833914af-5f07-5d32-e7e3-84ed369e63b7-a268b739,
#w-node-e9f06b0c-20da-3d8c-d734-28d0b4f8c9c7-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_833914af-5f07-5d32-e7e3-84ed369e63d4-a268b739 {
  justify-self: end;
}

#w-node-_74905a1c-1dfa-88aa-b28f-75904baf262b-a268b739,
#w-node-_5d728d88-0f71-039c-7e09-7417e3e08c95-a268b739,
#w-node-_67904a80-35c8-1670-b606-eddcf7152f2a-a268b739,
#w-node-cfe1f953-6c0f-d928-12c3-2db5c53aac91-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_797cb47f-0098-c487-ac4c-b893072761ac-a268b739,
#w-node-_1cefa882-b79d-2fbd-9752-4457e4c5608e-a268b739,
#w-node-a95f95be-abdc-7527-4a3a-6f36a27d1300-a268b739,
#w-node-_9d242813-5684-3039-21b8-b4c40eb13662-a268b739 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: center;
}

#w-node-b0ba0ee1-3980-3385-ca6a-6fd1e23d9ea8-96ae5224,
#w-node-b0ba0ee1-3980-3385-ca6a-6fd1e23d9ea9-96ae5224,
#w-node-b0ba0ee1-3980-3385-ca6a-6fd1e23d9eab-96ae5224,
#w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad32f-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad34c-96ae5224 {
  justify-self: end;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f5f-96ae5224 {
  justify-self: start;
}

#w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ac78ca84-8796-2e96-c652-fd50203bf9ff-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ac78ca84-8796-2e96-c652-fd50203bfa1c-96ae5224 {
  justify-self: end;
}

#w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b6-96ae5224 {
  justify-self: start;
}

#w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b8-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_52be971d-cfd5-8815-4291-eb87b20766dd-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_52be971d-cfd5-8815-4291-eb87b20766eb-96ae5224 {
  justify-self: end;
}

#w-node-_92e84895-938d-b5ec-c691-8716ff367d06-96ae5224 {
  justify-self: start;
}

#w-node-_92e84895-938d-b5ec-c691-8716ff367d08-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_833914af-5f07-5d32-e7e3-84ed369e63b7-96ae5224,
#w-node-e9f06b0c-20da-3d8c-d734-28d0b4f8c9c7-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_833914af-5f07-5d32-e7e3-84ed369e63d4-96ae5224 {
  justify-self: end;
}

#w-node-_74905a1c-1dfa-88aa-b28f-75904baf262b-96ae5224,
#w-node-_5d728d88-0f71-039c-7e09-7417e3e08c95-96ae5224,
#w-node-_67904a80-35c8-1670-b606-eddcf7152f2a-96ae5224,
#w-node-cfe1f953-6c0f-d928-12c3-2db5c53aac91-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_797cb47f-0098-c487-ac4c-b893072761ac-96ae5224,
#w-node-_1cefa882-b79d-2fbd-9752-4457e4c5608e-96ae5224,
#w-node-a95f95be-abdc-7527-4a3a-6f36a27d1300-96ae5224,
#w-node-_9d242813-5684-3039-21b8-b4c40eb13662-96ae5224 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 991px) {
  #w-node-_16585bea-bac4-5cc4-631c-9a13981ccbc4-156ff136 {
    justify-self: start;
  }

  #w-node-a8ba4b2b-2265-1878-55ad-9d0f866719c4-156ff136,
  #w-node-_998b9630-aced-1b56-cdd2-c0a9f299a358-156ff136,
  #w-node-f7efd667-51ee-cb60-2644-76485ab3fd01-156ff136,
  #w-node-_71299335-cd61-60a4-94e0-c2cdbdc61bb6-156ff136,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f2d-316ff138,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f55-316ff138,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f83-316ff138 {
    justify-self: center;
  }

  #w-node-_16ed6074-db2a-e44e-166f-fff4a0cb6276-8a66a138,
  #w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980245-8a66a138,
  #w-node-_16585bea-bac4-5cc4-631c-9a13981ccbc4-d4b5fde4 {
    justify-self: start;
  }

  #w-node-a8ba4b2b-2265-1878-55ad-9d0f866719c4-d4b5fde4,
  #w-node-_998b9630-aced-1b56-cdd2-c0a9f299a358-d4b5fde4,
  #w-node-f7efd667-51ee-cb60-2644-76485ab3fd01-d4b5fde4,
  #w-node-_71299335-cd61-60a4-94e0-c2cdbdc61bb6-d4b5fde4,
  #w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad34c-a268b739,
  #w-node-ac78ca84-8796-2e96-c652-fd50203bfa1c-a268b739,
  #w-node-_52be971d-cfd5-8815-4291-eb87b20766eb-a268b739,
  #w-node-_833914af-5f07-5d32-e7e3-84ed369e63d4-a268b739,
  #w-node-a51abf25-b6d7-0dbd-7399-1cd0844ad34c-96ae5224,
  #w-node-ac78ca84-8796-2e96-c652-fd50203bfa1c-96ae5224,
  #w-node-_52be971d-cfd5-8815-4291-eb87b20766eb-96ae5224,
  #w-node-_833914af-5f07-5d32-e7e3-84ed369e63d4-96ae5224 {
    justify-self: center;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_976a2096-87e1-b17b-c5c0-b4ca5465b8ef-156ff136,
  #w-node-_832258a5-3ef6-85d7-7501-c718fcf69908-156ff136,
  #w-node-_498b9957-9f73-e65f-d909-a726122f6807-156ff136,
  #w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba57b-156ff136,
  #w-node-_543d9649-a434-2581-1cf0-272ce13f57e6-156ff136,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f3c-316ff138,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-316ff138,
  #w-node-_832258a5-3ef6-85d7-7501-c718fcf69908-d4b5fde4,
  #w-node-_498b9957-9f73-e65f-d909-a726122f6807-d4b5fde4,
  #w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba57b-d4b5fde4,
  #w-node-_543d9649-a434-2581-1cf0-272ce13f57e6-d4b5fde4,
  #w-node-_3cce74df-0682-2b98-86f3-62b9db8e1af6-db8e1ae3,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-a268b739,
  #w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b8-a268b739,
  #w-node-_92e84895-938d-b5ec-c691-8716ff367d08-a268b739,
  #w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-96ae5224,
  #w-node-_41d35083-ef37-5804-bf7b-cf6fb5ec68b8-96ae5224,
  #w-node-_92e84895-938d-b5ec-c691-8716ff367d08-96ae5224 {
    order: -9999;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_00fd72af-3904-28e8-94e8-5ae26420825a-96ae5224 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: center;
  }

  #w-node-_8de20133-200f-288a-0e8b-d0831e1a1a76-96ae5224 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-ea6a469f-69ed-b131-f28d-505efedadec5-96ae5224 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: center;
  }

  #w-node-_833914af-5f07-5d32-e7e3-84ed369e63b7-96ae5224,
  #w-node-ae944a3f-85b8-9f83-a3a2-30895b42d775-96ae5224,
  #w-node-b8f30c1f-a8d1-14f4-e934-90dac6d46cf6-96ae5224,
  #w-node-d6af4a48-7983-4d06-9f56-1895524a42e2-96ae5224,
  #w-node-_9a1a6155-c8cd-e360-14f0-f850302e31b9-96ae5224,
  #w-node-af32a76b-b9f1-458d-96cf-daaf41332975-96ae5224,
  #w-node-c8a472ba-b775-9930-2fc8-12e349bec1ed-96ae5224,
  #w-node-_17ad1369-ced0-2602-3db4-8f6766104414-96ae5224,
  #w-node-_05253a41-b2a2-2143-7f56-22eca145c7a6-96ae5224 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}
