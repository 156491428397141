@import "../../../assets/styles/variables.scss";

.calendly {
  display: block;
  position: relative;
  border-radius: $preview-section-border-radius;
  margin: 15px auto;
  padding: 15px 0px;
  background-color: $default-section-bg;

  .calendly_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 0px 20px;

    .calendly_heading {
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      width: calc(100% - 40px);
    }

    .calendly_icon {
      display: block;
      $icon-size: 25px;
      width: $icon-size;
      height: $icon-size;

      img,
      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .widget {
    width: 95%;
    margin: 20px auto 10px auto;
    height: 550px;
    overflow: hidden;
    border-radius: $preview-section-border-radius;
  }
}
