/* Snippet gets rid of top margin on first element in any rich text*/
.w-richtext > :first-child {
  margin-top: 0;
}
/* Snippet gets rid of bottom margin on last element in any rich text*/
.w-richtext > :last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
  margin-bottom: 0;
}
/* Snippet prevents all click and hover interaction with an element */
.clickable-off {
  pointer-events: none;
}
/* Snippet enables all click and hover interaction with an element */
.clickable-on {
  pointer-events: auto;
}
/* Snippet enables you to add class of div-square which creates and maintains a 1:1 dimension of a div.*/
.div-square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
/*Hide focus outline for main content element*/
main:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
/* Make sure containers never lose their center alignment*/
.container-medium,
.container-small,
.container-large {
  margin-right: auto !important;
  margin-left: auto !important;
}
/*Reset buttons, and links styles*/
a {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}
/*Apply "..." after 3 lines of text */
.text-style-3lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/*Apply "..." after 2 lines of text */
.text-style-2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.slider-arrow {
  display: none !important;
}
.w-slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 20px !important;
  background-color: rgba(23, 151, 255, 0.3) !important;
  transition: all 0.25s ease-in-out;
}
.w-slider-dot.w-active {
  border-radius: 20px !important;
  width: 20px;
  height: 8px;
  background-color: #1797ff !important;
}

.rdrMonthAndYearWrapper {
  background-color: #1BA8F0;
  color: #fff;
  height: 40px;
  padding: 0;
}

.rdrMonthPicker > select, .rdrYearPicker > select {
  color: #fff;
}

/* .rdrMonthAndYearPickers select {

} */