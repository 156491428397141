@import "../../../assets/styles/global.scss";

.color_picker_container {
  width: 100%;
  margin-top: 15px;

  .color_picker_bg_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 7;
  }

  .picker_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .picker_item {
      &.hex {
        width: 20%;
      }
      &.rgb {
        width: 30%;
      }
      &.picker_tool {
        width: 5%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;

        img {
          cursor: pointer;
          transform-origin: center;

          &:hover {
            transform: rotate(30deg);
          }
        }

        .color_picker_body_popup {
          position: absolute;
          top: 115%;
          left: 0;
          z-index: 8;
        }
      }
      &.opacity,
      &.color_output {
        width: 15%;
      }

      &.color_output {
        display: flex;
        align-items: flex-end;

        .color_output_show {
          // border: none !important;
          // border: $image-upload-drop-zone-border;
          background-color: $element-active-color;
        }
      }

      .picker_heading {
        font-size: $small-font-size;
        color: $text-color-light;
        margin-bottom: 5px;
      }

      .picker_inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .picker_input {
          width: 100%;
          display: block;
          border: $image-upload-drop-zone-border;
          padding: 10px 12px;
          border-radius: 5px;
          background-color: #fff;
          outline: none;

          &.three_divisons {
            width: 32%;
            padding: 10px 4px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .color_picker_container {
    .picker_inner {
      .picker_item {
        &.hex,
        &.rgb {
          width: 49%;
          margin-bottom: 10px;
        }

        &.picker_tool {
          width: 20%;
        }

        &.opacity,
        &.color_output {
          width: 38%;
        }
      }
    }
  }
}
