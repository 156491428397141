@import "../../../assets/styles/global.scss";

.input_field_container {
  width: 100%;
  margin: 40px auto 5px auto;
  position: relative;
  display: inline-block;

  label {
    display: block;
    width: 100%;

    &.draggable_field {
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .draggable_icon {
        position: relative;
      }
    }

    .delete_icon {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 25px;
      height: 25px;
      padding: 3px;
      background-color: $danger-color;
      border-radius: 50%;

      span,
      svg {
        width: 100%;
      }
    }

    .prefix {
      background: $element-light-blue-color;
      color: $element-active-color;
      height: 100%;
      width: auto;
      padding: 6px;
      border-radius: 4px;

      & + input {
        margin-left: 6px;
      }
    }

    input {
      width: 100%;
      display: block;
      padding: 10px 12px;
      border: $image-upload-drop-zone-border;
      border-radius: 5px;
      background-color: #fff;
      font-size: $normal-font-size;
      outline: none;

      &:focus {
        box-shadow: $component-box-shadow;

        & + .label_text {
          color: $element-active-color;
        }
      }
    }

    .label_text {
      width: 100%;
      display: block;
      position: absolute;
      top: -22px;
      font-size: $small-font-size;
      font-weight: bold;
      color: $text-color-light;
    }
  }

  &.fifty {
    width: 49%;
    margin-right: 1%;
  }
  &.fifty_2 {
    width: 49%;
    margin-left: 1%;
  }
  &.sixty {
    width: 65%;
    margin-right: 5%;
  }
  &.thirty {
    width: 30%;
  }
  &.no_label {
    margin-top: 20px;

    .label_text {
      display: none;
    }
  }
  &.social_link_url {
    width: 100%;
    display: block;
    padding: 5px 12px 5px 45px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    margin: 0;

    label {
      input {
        border: none;
      }
    }
  }

  &.prefix {
    label {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .input_field_container {
    width: 100%;
    margin: 40px auto 5px auto;

    &.fifty,
    &.fifty_2 {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .draggable_icon {
      left: -8px;
    }
  }
}
