@import "../../../../../assets/styles/global.scss";

.image_link_style_selectors_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 15px;
  justify-content: space-between;
}
