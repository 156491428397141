.calendly_skeleton {
  width: 100%;
  margin: 20px auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: 355px;

  [aria-live][aria-busy] {
    display: block;
    width: 100%;
  }

  .main_bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .header_text {
    width: 50% !important;
    position: absolute;
    top: 15px;
    left: 20px;
  }

  .cta_main_icon {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    --base-color: #8ea8c3;
    --highlight-color: #5d87b2;
  }

  .calendar_container {
    width: calc(100% - 40px) !important;
    height: calc(100% - 70px);
    position: absolute;
    top: 49px;
    left: 20px;
    border-radius: 10px;
    overflow: hidden;

    .calendar {
      width: 100%;
      height: 100%;
      --base-color: #9893da;
      --highlight-color: #ada9e4;
    }
  }

  .text {
    display: block;
    margin: 0px auto;
    line-height: 24px;
    --base-color: #9e9e9e;
    --highlight-color: #c1baba;
  }
}
