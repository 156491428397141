@import "../../../assets/styles/variables.scss";

.info {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #505050;
  text-align: left;
}

.cta_selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px auto;

  .icon_box {
    width: 50%;
    padding: 10px;

    .fa_picker {
      width: 100%;
      margin-top: 15px;
      display: flex;
      flex-direction: row;

      .demo {
        width: 50%;
        padding: 0;
        min-height: 120px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
          display: block;
          width: 70%;
          height: 70%;

          path {
            fill: $default-section-bg;
          }
        }
      }

      .selector {
        width: 50%;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .select_btn {
          width: 96%;
          padding: 12px;
          font-size: $small-font-size;
          background-color: $element-light-blue-color;
          color: $element-active-color;
        }
      }
    }
  }
}

.cta_selections {
  display: block;
  width: 100%;
  background-color: $background-color;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cta_selection {
    background-color: transparent;
    width: 25%;
    padding: 10px;
    height: 120px;
    cursor: pointer;

    .cta_inner {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      transition: all 300ms ease-in-out;

      svg {
        display: block;
        width: 40%;
        height: 40%;

        path {
          fill: $default-section-bg;
        }
      }

      .title {
        font-size: 11px;
        color: $default-section-bg;
        text-align: center;
        margin: 0;
      }
    }

    &:hover {
      .cta_inner {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .cta_selector {
    .icon_box {
      width: 100%;
    }
  }
}
