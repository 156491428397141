.stickyFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: #f4f7fe;
  box-shadow: -5px 0px 20px rgba(142, 152, 172, 0.2);
  z-index: 99999999;

  .share_card_btn {
    display: block;
    width: 100%;
    padding: 10px;
    background: #6182f6;
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    .share_symbol {
      background: rgba(0, 0, 0, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;

      span {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        svg {
          display: block;
          width: 60%;
          height: 60%;
        }
      }
    }
  }
}

.qr_code {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  transition: all 300ms ease-in-out;

  &.show {
    opacity: 1;
    pointer-events: unset;
  }

  &.preview {
    height: calc(100% - 70px);
    position: absolute;
  }
}
