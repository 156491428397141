@import "../../../assets/styles/global.scss";

.top_bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .heading {
    font-size: $big-heading;
    line-height: $big-heading-line-height;
    color: $text-color-light;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .help {
      background-color: transparent;
      border: none;
      outline: none;
      user-select: none;
      margin-left: 8px;
      cursor: pointer;

      span {
        $size: 25px;
        width: $size;
        height: $size;
        display: flex;

        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all 250ms ease-in-out;
          }
        }
      }

      &:hover {
        svg path {
          fill: $element-active-color;
        }
      }
    }
  }

  .action_btns {
    display: flex;

    .clear_all {
      color: $danger-color;
      font-size: $normal-font-size;
      font-weight: bold;
      background-color: $danger-bg-color;
      padding: 10px 16px;
      border-radius: 4px;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        margin-top: 3px;
      }

      &.update_btn {
        background-color: $element-active-color-light-bg;
        color: $element-active-color;
        margin-right: 20px;

        svg path,
        svg rect {
          fill: $element-active-color;
        }
      }
    }
  }

  .floating_save_button {
    color: $danger-color;
    font-size: $normal-font-size;
    font-weight: bold;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: $element-active-color-light-bg;
    color: $element-active-color;
    position: fixed;
    bottom: 16px;
    right: 138px;
    opacity: 1;
    pointer-events: unset;
    z-index: 999999999;

    svg {
      width: 15px;
      height: 15px;
      margin-top: 3px;
      background-color: $element-active-color-light-bg;
      color: $element-active-color;
      margin-right: 8px;

      path,
      rect {
        fill: $element-active-color;
      }
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .top_bar {
    margin-bottom: 12px;
    flex-wrap: wrap;

    .heading {
      font-size: $big-heading-mobile;
      line-height: $big-heading-line-height-mobile;
      width: 100%;
      margin-bottom: 10px;
    }

    .action_btns {
      width: 100%;

      .clear_all {
        width: 49%;
        justify-content: center;
        font-size: $small-font-size;
        padding: 6px 12px;
      }
    }
  }
}
