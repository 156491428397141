@import "../../../assets/styles/global.scss";

.popup_bg_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  .popup_container {
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    background-color: $component-bg-color;
    box-shadow: $component-box-shadow;
    padding: 20px 30px;
    margin-bottom: 25px;
    width: 100%;
    position: relative;

    .lead_form_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;

      .popup_main_heading,
      h3 {
        margin: 0;
      }
    }

    .closePopupButton {
      background-color: transparent;
      outline: none;
      border: none;
      user-select: none;
      cursor: pointer;
    }

    .popup_main_heading,
    h3 {
      font-size: $large-font-size;
      color: $text-color-dark;
      margin: 20px auto;
    }

    .popup_sub_heading,
    h4 {
      font-size: $normal-font-size;
      color: $text-color-dark;
      margin: 10px auto;
    }

    .popup_info_text,
    p {
      font-size: $semi-small-font-size;
      color: $text-color-dark;
      margin: 10px auto;
    }

    .lead_radio_text {
      font-size: $normal-font-size;
    }

    .popup_error_text {
      font-size: $semi-small-font-size;
      color: $danger-color;
      margin: 10px auto;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .popup_bg_layer {
    .popup_container {
      transform: translateY(-12%);
    }
  }
}
