@import "../../../assets/styles/global.scss";

.counter_input_container {
  width: 100%;
  display: flex;
  margin: 15px auto;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .label {
    font-size: $normal-font-size;
    color: $text-color-dark;
    text-align: left;
  }
  .counter_container {
    display: flex;
    flex-direction: row;
    height: 35px;
    border: $add-section-btn-border;
    border-radius: 4px;
    padding: 10px, 12px, 10px, 12px;

    .plus_minus_button {
      width: 30px;
      font-size: $normal-font-size;
      color: $info-text-color;

      &:hover {
        background-color: $element-active-color-light-bg;
      }
    }

    .reset_btn {
      background-color: $element-active-color-light-bg;
      padding: 0 6px;
      svg {
        width: 20px;
        height: 20px;
        transition: all 300ms ease-in-out;

        path {
          fill: $element-active-color;
        }
      }

      &:hover {
        svg {
          transform: rotate(-50deg);
        }
      }
    }

    .number_input {
      width: 50px;
      height: 100%;
      font-size: $small-font-size;
      text-align: center;
      outline: none;
      border: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
