@import "../../../assets/styles/variables.scss";

.pricing_tables {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .pricing_table {
    width: 30%;
    border: $image-upload-drop-zone-border;
    padding: 25px 15px;

    .main_heading {
      font-size: 24px;
      line-height: 38px;
      color: $text-color-dark;
      text-align: center;
    }

    .info_text {
      font-size: 15px;
      line-height: 20px;
      color: $text-color-light;
      text-align: center;
    }

    .plan_description_text {
      font-size: 14px;
      line-height: 18px;
      color: $text-color-light;
      text-align: left;
      margin: 25px auto;
    }

    .small_heading {
      font-size: 15px;
      line-height: 20px;
      color: $text-color-dark;
      text-align: left;
      margin-bottom: 25px;
    }

    .features_list {
      width: 100%;

      .feature {
        padding: 5px;
        display: flex;
        align-items: center;

        span svg {
          margin-top: 5px;
        }

        span svg rect {
          fill: #d1d5d8;
        }

        .feature_text {
          font-size: 16px;
          color: $text-color-dark;
          margin-left: 8px;
        }

        &.available {
          span svg rect {
            fill: $element-active-color;
          }
        }
      }
    }

    .get_started_btn {
      display: block;
      width: 80%;
      margin: 25px auto 0 auto;
      border: 1px solid $element-active-color;
      color: $element-active-color;
      outline: none;
      font-size: 16px;
      font-weight: bold;
      padding: 10px;

      &:hover {
        transform: translateX(10px);
      }
    }

    &.best {
      border-color: $element-active-color;
      transform-origin: center;
      transform: scale(1.05);

      .get_started_btn {
        background-color: $element-active-color;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .pricing_tables {
    .pricing_table {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}
