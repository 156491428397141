@import "../../../assets/styles/global.scss";

.option:nth-last-of-type(1),
.option:nth-last-of-type(2),
.option:nth-last-of-type(3) {
  opacity: 0.4;
}

.select_option {
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 15px;
    height: 15px;
    margin-top: -1px;
    background-color: transparent !important;
    color: #505050 !important;

    path,
    rect {
      fill: #505050 !important;
    }
  }
}

.hello {
  svg {
    width: 15px;
    height: 15px;
    margin-top: -1px;
    background-color: "transparent";
    color: "#505050";

    path,
    rect {
      fill: "#505050"
    }
  }
}