@import "../../../assets/styles/global.scss";

.input_field_container {
  width: 100%;
  margin: 40px auto 5px auto;
  position: relative;
  display: inline-block;

  label {
    display: block;
    width: 100%;

    textarea {
      width: 100%;
      min-height: 150px;
      display: block;
      padding: 10px 12px;
      border: $image-upload-drop-zone-border;
      border-radius: 5px;
      background-color: #fff;
      font-size: $normal-font-size;
      outline: none;
      resize: none;

      &:focus {
        box-shadow: $component-box-shadow;

        & + .label_text {
          color: $element-active-color;
        }
      }
    }

    .label_text {
      width: 100%;
      display: block;
      position: absolute;
      top: -22px;
      font-size: $small-font-size;
      font-weight: bold;
      color: $text-color-light;
    }
  }

  &.sixty {
    width: 65%;
    margin-right: 5%;
  }
  &.thirty {
    width: 30%;
  }
  &.no_label {
    margin-top: 20px;

    .label_text {
      display: none;
    }
  }
}
