.video_gallery_skeleton {
  width: 100%;
  margin: 20px auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: 355px;

  [aria-live][aria-busy] {
    display: block;
    width: 100%;
  }

  .main_bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .header_text {
    width: 50% !important;
    position: absolute;
    top: 15px;
    left: 20px;
  }

  .cta_main_icon {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    --base-color: #8ea8c3;
    --highlight-color: #5d87b2;
  }

  .header_sub_text {
    width: 35% !important;
    position: absolute;
    top: 50px;
    left: 20px;
  }

  .gallery_main_video {
    position: absolute;
    top: 85px;
    left: 20px;
    width: calc(100% - 40px);
    height: 130px;
    border-radius: 10px;
    --base-color: #222f4f;
    --highlight-color: #304066;
  }

  .cta_bg {
    position: absolute;
    top: 232px;
    left: 20px;
    width: calc(100% - 40px);
    height: 40px;
    border-radius: 10px;
    --base-color: #87929d;
    --highlight-color: #a2aab2;
  }

  .cta_icon {
    position: absolute;
    top: 242px;
    left: 32px;
    width: 20px;
    height: 20px;
  }

  .cta_text {
    position: absolute;
    top: 243px;
    left: 64px;
    width: 50% !important;
  }

  .divider {
    position: absolute;
    top: 285px;
    left: 20px;
    display: block;
    height: 1px;
    width: calc(100% - 40px);
    background-color: #afafaf;
    z-index: 1;
  }

  .list {
    position: absolute;
    top: 290px;
    left: 20px;
    height: 50px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .item {
      width: 25% !important;
      height: 50px;
      padding: 4px;

      .img {
        width: 100%;
        height: 100%;
        display: block;
        --base-color: #222f4f;
        --highlight-color: #304066;
      }
    }
  }

  .text {
    display: block;
    margin: 0px auto;
    line-height: 24px;
    --base-color: #9e9e9e;
    --highlight-color: #c1baba;
  }

  .text_small {
    display: block;
    margin: 0px auto;
    line-height: 18px;
    --base-color: #9e9e9e;
    --highlight-color: #c1baba;
  }
}
