$background-color: #e5e5e5;
$component-bg-color: #ffffff;
$designer-component-bg: #fcfcfc;
$component-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
$component-inset-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.08),
  inset 0px -1px 0px #f0f0f0;
$component-inset-shadow-light: inset -1px 0px 0px #f0f0f0;
$default-section-bg: #222f4f;
$text-color-light: #858585;
$text-color-dark: #505050;
$info-text-color: #858585;
$element-active-color: #1ba8f0;
$element-light-blue-color: #e4f2f9;
$element-active-color-light-bg: #e9f7fe;
$danger-color: #f36868;
$danger-color-dark: #f92d2d;
$danger-bg-color: #f7e4e4;
$lock-color: #9ca5c6;
$large-font-size: 20px;
$small-font-size: 13px;
$supersmall-font-size: 11px;
$semi-small-font-size: 14px;
$normal-font-size: 16px;
$white-box-heading: 18px;
$white-box-heading-line-height: 23px;
$big-heading: 28px;
$big-heading-mobile: 22px;
$add-section-btn-border: 1px solid #e8e8e8;
$image-upload-drop-zone-border: 1px solid #e8e8e8;
$big-heading-line-height: 35.28px;
$big-heading-line-height-mobile: 22.28px;
$editable-section-top-margin: 15px;
$section-inner-padding: 0px 25px;
$section-inner-padding-mobile: 0px 0px;
$preview-section-border-radius: 10px;

$medium-breakpoint: 1280px;
$mobile-breakpoint: 768px;
$mobile-xs-breakpoint: 480px;

@media only screen and (max-width: $mobile-breakpoint) {
  $section-inner-padding: 0px 0px;
}
