@import "../../assets/styles/variables.scss";

.designer_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 30px 0px;

  .section_selector.floating_selection {
    .floating_button_designer {
      display: none;
    }
  }

  .section_selector {
    width: 25%;
    padding: $section-inner-padding;
  }

  .section_details_editor {
    width: 50%;
    padding: $section-inner-padding;

    .details_editor {
      padding: 24px;
      margin-bottom: 25px;

      .editable_section {
        position: relative;
        background-color: $designer-component-bg;
        padding: 20px 16px;
        margin: 8px 0;

        .section_heading {
          color: $text-color-dark;
          font-size: $normal-font-size;
          font-weight: bold;
          margin-bottom: 10px;

          &.heading_with_icon_left {
            display: flex;
            align-items: center;

            .inner_text {
              width: auto;
              margin-left: 15px;
            }

            span,
            svg {
              height: 18px;
              width: 18px;
            }
          }
        }

        .section_info_text {
          color: $info-text-color;
          font-size: $small-font-size;
          line-height: 22px;
          margin-top: 10px;
        }

        .profile_pic_border_styles {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 15px;
          margin-top: 30px;
          padding: 0 20px 0 20px;
        }

        .cardDesign_options {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
        }

        .arrange_gallery_items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .arrange_item_div {
            display: block;
            width: 100px;
            height: 100px;
            margin: 15px;
            border-radius: 10px;
            overflow: hidden;
            cursor: move;
            box-shadow: $component-box-shadow;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .arrange_div_block {
              padding: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              p {
                text-align: center;
                font-weight: bold;
              }
            }
          }
        }

        .leads_contact_details_form {
          margin-top: 30px;
        }

        .copy_to_clipboard {
          font-size: $normal-font-size;
          color: $element-active-color;
          display: block;
          width: 100%;
          margin-top: 10px;
          text-align: left;

          svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
        }

        .help {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 20px;
          height: 20px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .label_sub_info {
          display: block;
          font-size: $small-font-size;
          color: $text-color-light;
          font-weight: normal;
          margin-left: 32px;
        }
      }
    }
  }

  .designer_white_box {
    background-color: $component-bg-color;
    box-shadow: $component-box-shadow;
    padding: 20px 15px;
    margin-bottom: 25px;
    width: 100%;

    .heading {
      font-size: $white-box-heading;
      line-height: $white-box-heading-line-height;
      color: $text-color-dark;
      font-weight: bold;
      margin-left: 5px;
    }

    .customize_options_container {
      width: 100%;
      margin-top: 20px;

      .customize_option {
        color: $text-color-light;
        font-size: $normal-font-size;
        font-weight: bold;
        display: block;
        width: 100%;
        text-align: left;
        padding: 12px 16px;
        margin: 10px auto;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          margin-bottom: -3px;
        }

        &.active {
          background-color: $element-active-color;
          color: #fff;
          box-shadow: $component-box-shadow;

          svg path {
            fill: #fff;
          }
        }

        &:not(.active):hover {
          background-color: $element-active-color-light-bg;
          box-shadow: $component-box-shadow;
          color: $text-color-dark;

          svg path {
            fill: $text-color-dark;
          }
        }
      }

      .add_new_section {
        background-color: $element-active-color-light-bg;
        color: $element-active-color;
        font-size: $normal-font-size;
        font-weight: bold;
        padding: 12px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }

      .add_the_section {
        position: relative;
        border: $add-section-btn-border;
        color: $element-active-color;
        font-size: $normal-font-size;
        font-weight: bold;
        padding: 12px;
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
        cursor: unset;
        transition: all 200ms ease-in-out;

        .ellipses {
          $ellipse-width: 3px;
          $ellipse-distance: 8px;
          $ellipse-color: #afafaf;
          width: $ellipse-width;
          height: $ellipse-width;
          background-color: $ellipse-color;
          margin-right: 20px;
          border-radius: 50%;
          position: relative;

          &::before {
            content: "";
            width: $ellipse-width;
            height: $ellipse-width;
            background-color: $ellipse-color;
            border-radius: 50%;
            position: absolute;
            top: -$ellipse-distance;
            left: 0;
          }

          &::after {
            content: "";
            width: $ellipse-width;
            height: $ellipse-width;
            background-color: $ellipse-color;
            border-radius: 50%;
            position: absolute;
            bottom: -$ellipse-distance;
            left: 0;
          }
        }

        svg {
          width: 22px;
          height: 22px;
          margin-bottom: -5px;
          margin-right: 10px;
        }

        &:hover {
          box-shadow: $component-box-shadow;
        }

        &.active {
          background-color: $element-active-color;
          color: #fff;
          transform: scale(1.05);

          .ellipses,
          .ellipses::before,
          .ellipses::after {
            background-color: #fff;
          }

          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .designer_container {
    padding-left: 0;
    padding-right: 0;

    .section_selector {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: $medium-breakpoint) {
  .designer_container {
    .section_selector.floating_selection {
      width: 40%;
      position: fixed;
      left: 0;
      top: 64px;
      z-index: 999;
      background: #ccc;
      overflow: visible;
      height: calc(100vh - 64px);
      transform: translateX(-100%);
      transition: all 300ms ease-in-out;

      .allow_scroll_mobile {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 100px;

        .designer_white_box {
          width: 95%;
          margin: 10px auto;
        }
      }

      .floating_button_designer {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid $element-active-color;
        background-color: #ffffff;
        position: absolute;
        right: -45px;
        top: calc(35% - 35px);
        z-index: 9999999;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center;
        transform: rotate(180deg);
        transition: all 600ms ease-in-out;

        span {
          display: block;
          width: 40%;
          height: 40%;
          display: flex;

          span {
            width: 100%;
            height: 100%;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &.open {
        transform: translateX(0);
        .floating_button_designer {
          transform: rotate(0deg) translateX(-25px);
        }
      }
    }

    .section_details_editor {
      width: 65%;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .designer_container {
    flex-direction: column;
    padding: 10px 5px;

    .section_selector,
    .section_details_editor {
      width: 100%;
      padding: $section-inner-padding-mobile;
    }

    .section_selector.floating_selection {
      width: 100%;
    }

    .section_details_editor {
      .designer_white_box.details_editor {
        padding: 12px;
        margin-bottom: 12.5px;

        .editable_section {
          padding: 16px 8px;
        }
      }
    }
  }
}
