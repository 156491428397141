@import "../../../assets/styles/variables.scss";

.analytic_section {
  position: relative;
  background-color: $designer-component-bg;
  padding: 20px 16px;
  margin: 8px 0;
}

.analytic_bar {
  position: relative;
  background-color: $designer-component-bg;
  padding: 20px 16px;
  margin: 8px 0;

  .chart_section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content_section {
      .single_item {
        text-align: center;
        margin-bottom: 0.5rem;
        .title_section {
          display: flex;
          align-items: center;
          font-size: 13px;
          color: $text-color-light;

          svg {
            width: 8px;
            height: 8px;
            margin-right: 5px;
            margin-bottom: 1px;
          }
        }
        .views {
          svg path,
          svg rect {
            fill: #81f2f9;
          }
        }

        .clicks {
          svg path,
          svg rect {
            fill: $element-active-color;
          }
        }

        .unique {
          svg path,
          svg rect {
            fill: #75d89d;
          }
        }

        .ctr {
          svg path,
          svg rect {
            fill: #fcc97b;
          }
        }

        .time {
          svg path,
          svg rect {
            fill: #e97d85;
          }
        }

        .earning {
          svg path,
          svg rect {
            fill: #ff81dc;
          }
        }

        .value {
          font-weight: 700;
        }
      }
    }
  }

  .chart_container {
    display: flex;
    justify-content: center;
  }

  .list_container {
    margin: 30px 0px;

    .list_item {
      display: flex;
      justify-content: space-between;
      padding: 8px 0px;
      position: relative;
      font-size: 13px;
      font-weight: 500;
      color: $text-color-dark;

      .value {
        font-weight: 700;
      }

      &::after {
        content: "";
        // display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e2e2e2;
      }
    }
  }

  .section_top {
    display: flex;
    justify-content: space-between;

    .section_options {
      display: flex;
      font-size: 12px;
      color: $text-color-dark;

      p {
        margin-right: 16px;
        padding-bottom: 3px;
        position: relative;
      }

      .selected_option {
        position: relative;
        &::after {
          content: "";
          // display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $element-active-color;
        }
      }
    }
  }

  .info_section {
    font-size: 13px;
    color: $text-color-dark;
  }
}

@media only screen and (max-width: $mobile-xs-breakpoint) {
  .analytic_bar {
    .chart_section {
      display: block;

      .content_section {
        margin-top: 1rem;
      }
    }
  }
}
