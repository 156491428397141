@import "../../../assets/styles/global.scss";

.radio_field_container {
  display: block;
  width: 100%;
  margin: 10px auto;

  .radio_labal {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    input[type="radio"] {
      width: 22px;
      height: 22px;
      cursor: pointer;

      &:checked + .radio_body {
        opacity: 1;
        pointer-events: unset;
      }
    }

    .radio_body {
      width: calc(100% - 22px);
      padding: 0 0 0 10px;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.left_icon_w_background {
    width: auto;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;

    .radio_labal {
      cursor: pointer;

      input[type="radio"] {
        position: absolute;
        width: 0 !important;
        height: 0 !important;
        opacity: 0;

        &:checked + .radio_body {
          .left_icon_body {
            background-color: $element-active-color;

            svg path,
            .left_icon_text {
              fill: #fff;
              color: #fff;
            }
          }
        }
      }

      .radio_body {
        width: auto;
        padding: 0;
        opacity: 1;
      }
    }

    .left_icon_body {
      background-color: #f5f5f5;
      display: inline-block;
      width: auto;
      padding: 10px 20px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      svg path,
      .left_icon_text {
        fill: $text-color-light;
      }

      .left_icon_text {
        margin-left: 8px;
        font-size: $semi-small-font-size;
      }
    }
  }

  &.pill_radio {
    width: auto;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;

    .radio_labal {
      cursor: pointer;

      input[type="radio"] {
        position: absolute;
        width: 0 !important;
        height: 0 !important;
        opacity: 0;

        &:checked + .radio_body {
          .pill_body {
            background-color: $element-active-color;
            border-color: $element-active-color;

            .pill_body_text {
              color: #fff;
            }
          }
        }
      }

      .radio_body {
        width: auto;
        padding: 0;
        opacity: 1;
      }
    }

    .pill_body {
      background-color: transparent;
      display: inline-block;
      width: auto;
      padding: 10px 30px;
      border-radius: 24px;
      border: $image-upload-drop-zone-border;
      display: flex;
      align-items: center;

      .pill_body_text {
        color: $text-color-light;
        font-size: $semi-small-font-size;
      }
    }
  }

  &.carddesign_radio {
    width: 40%;

    .radio_labal {
      cursor: pointer;

      input[type="radio"] {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        top: 0;
        left: 0;
      }
    }

    img {
      width: 100%;
      border-radius: 12px;
      box-shadow: $component-box-shadow;
    }
  }
}
