.notification {
  width: 100%;
  min-height: 70px;
  border-radius: 8px;
  background-color: #fff;
  top: 10%;
  right: 0%;
  padding: 15px 10px 15px 10px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-30px);
  opacity: 0;
  transition: all 150ms ease-in-out;

  &.show {
    transform: translateY(0);
    opacity: 1;

    &.destroy {
      transform: translateX(50px);
      opacity: 0;
    }
  }

  .sign,
  .close {
    background-position: center center;
    background-size: contain;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    background-image: url(../../assets/global/icon_cross_grey.svg);
    cursor: pointer;
  }

  .message {
    width: calc(100% - 60px);
    margin-top: 10px;

    .heading {
      font-size: 17px;
      font-weight: 400;
      color: #121012;
      margin-bottom: 4px;
      text-transform: capitalize;
      margin-left: 0;
    }

    .details {
      font-size: 15px;
      color: #9ca1a5;
    }
  }

  &.success {
    box-shadow: -3px 0px 0px #41ce7a, 0px 10px 40px rgba(0, 0, 0, 0.1);

    .sign {
      background-image: url(../../assets/global/icon_success.svg);
    }
  }

  &.error {
    box-shadow: -3px 0px 0px #ff5454, 0px 10px 40px rgba(0, 0, 0, 0.1);

    .sign {
      background-image: url(../../assets/global/icon_error.svg);
    }
  }

  &.warning {
    box-shadow: -3px 0px 0px #fcbf24, 0px 10px 40px rgba(0, 0, 0, 0.1);

    .sign {
      background-image: url(../../assets/global/icon_warning.svg);
    }
  }

  &.info {
    box-shadow: -3px 0px 0px #1ba8f0, 0px 10px 40px rgba(0, 0, 0, 0.1);

    .sign {
      background-image: url(../../assets/global/icon_info.svg);
    }
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;

  .loader_icon {
    display: block;
    width: 150px;
    height: 150px;
    background-image: url(../../assets/global/loader.gif);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.destroy {
    opacity: 0;
  }
}
