@import "../../../assets/styles/global.scss";

.analytics_footer_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 15px;

  .analytics_footer_left {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .analytics_icon {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      span {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        svg path {
          fill: $element-active-color;
        }
      }
    }
  }

  .analytics_footer_right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete_btn {
      width: 30px;
      height: 30px;
      background-color: $danger-color-dark;
      border-radius: 50%;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      span,
      svg {
        width: 100%;
        height: 100%;

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .date_range_picker {
    margin-top: 10px;
    width: 100%;

    .range_picker_body {
      margin: 0 auto;
      display: block;
    }
  }
}
