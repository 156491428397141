@import "../../../assets/styles/variables.scss";

.password_protected {
  display: block;
  width: 100%;

  .lock_header {
    width: 100%;
    border-bottom: 1px solid #dddfe2;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    background-color: #fafafa;
    position: absolute;
    top: 0;
    left: 0;

    .protected_icon {
      width: 18px !important;
      display: block !important;
      margin-right: 12px !important;
    }

    .protected_heading {
      color: $text-color-dark;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      margin: unset;
    }

    .close_icon {
      width: 18px !important;
      position: absolute !important;
      right: 20px !important;
      top: 12px !important;
      height: unset !important;
      object-fit: none !important;
      cursor: pointer;
    }
  }

  .password_form {
    margin-top: 42px;

    .info {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: $text-color-dark;
    }

    button[type="submit"] {
      float: unset;
    }
  }
}
