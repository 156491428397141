@import "../../../assets/styles/variables.scss";

.switch {
  display: inline-block;
  width: 36px;
  height: 19px;
  position: absolute;
  right: 10px;

  & + span {
    margin-left: 15px;
    height: 100%;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $element-light-blue-color;

  &::before {
    background-color: $element-active-color;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px $element-active-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}
