@import "../../../assets/styles/global.scss";

.select_container {
  width: 100%;
  margin: 10px auto 5px auto;

  label {
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: #858585;
    margin-bottom: 5px;
  }

  select {
    width: 100%;
    display: block;
    padding: 10px 12px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;

    option {
      svg {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      background-color: $element-active-color-light-bg;
      color: $element-active-color;
      margin-left: 8px;
  
      path,
      rect {
        fill: $element-active-color;
      }
    }
  }
  }
}
