@import "../../assets/styles/global.scss";

.header {
  width: 100%;
  background-color: $component-bg-color;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: $component-inset-shadow;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;

  .header_left {
    height: 100%;
    margin-right: 25px;

    .logo_container {
      display: inline-block;
      height: 32px;

      .brand_logo {
        height: 100%;
      }
    }
  }

  .header_right {
    height: 100%;

    .user_drop_down {
      height: 100%;
      position: relative;

      .user_image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        .user_image_tag {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }

      .user_name {
        font-size: $normal-font-size;
        color: $text-color-dark;
        margin-left: 10px;
      }

      .user_dropdown_btn {
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
        margin-left: 10px;
      }

      .dropdown_menu {
        background-color: $component-bg-color;
        min-width: 200px;
        max-width: 90vh;
        position: absolute;
        top: 170%;
        right: -20px;
        display: none;

        a {
          -webkit-user-select: none;
          user-select: none;
          text-decoration: none;
          display: block;
          width: 100%;
          padding: 10px 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: $text-color-dark;
          display: flex;
          align-items: center;

          .icon {
            display: block;
            width: 15px;
            height: 15px;

            span,
            svg {
              width: 100%;
              height: 100%;
              display: flex;
              path {
                fill: $text-color-dark;
              }
            }
          }

          .url_text {
            padding-left: 6px;
          }

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}

.nav_links {
  height: 80px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;

  .nav_link {
    text-decoration: none;
    height: 100%;
    font-size: $normal-font-size;
    color: $text-color-light;
    position: relative;
    margin-right: 30px;

    .nav_link_inner {
      height: auto;

      svg {
        margin-right: 5px;
        margin-bottom: -3px;
        height: 18px;

        path {
          fill: $text-color-light;
        }
      }
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $element-active-color;
    }

    &:active,
    &:hover,
    &.active {
      color: $element-active-color;

      svg path {
        fill: $element-active-color;
      }

      &::after {
        display: block;
      }
    }
  }
}

.mobile_nav,
.hamburger_icon {
  display: none;
}

@media only screen and (max-width: $medium-breakpoint) {
  .nav_links {
    .nav_link {
      margin-right: 20px;
      font-size: $small-font-size;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .header {
    height: 64px;

    .header_left .nav_links,
    .header_right {
      display: none;
    }
  }
  .hamburger_icon {
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    position: relative;
    --distance: -10px;
    --stroke-width: 1.5px;

    .line {
      width: 100%;
      height: var(--stroke-width);
      background-color: $text-color-dark;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: var(--distance);
        width: 100%;

        height: var(--stroke-width);
        background-color: $text-color-dark;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: var(--distance);
        width: 100%;
        height: var(--stroke-width);
        background-color: $text-color-dark;
      }
    }

    &.closing {
      float: right;

      .line {
        transform: rotate(45deg);

        &::before {
          transform: rotate(-90deg) translateX(var(--distance));
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .mobile_nav {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 10px 15px;
    backdrop-filter: blur(20px);
    z-index: 9999999;
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }

    .mobile_nav_links {
      width: 100%;
      margin-top: 84px;

      .nav_links {
        flex-direction: column;
        margin-left: 0;

        .nav_link {
          margin-right: 0;
          margin-bottom: 20px;

          &::after {
            height: 2px;
            width: 50px;
            bottom: -10px;
          }
        }
      }
    }
  }
}
