@import "../../../../../assets/styles/global.scss";

.single_social_link_editor {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;

  .move_icon {
    width: 5%;
    align-items: flex-start;

    .ellipses {
      $ellipse-width: 3px;
      $ellipse-distance: 8px;
      $ellipse-color: #afafaf;
      width: $ellipse-width;
      height: $ellipse-width;
      background-color: $ellipse-color;
      margin-right: 20px;
      border-radius: 50%;
      position: relative;

      &::before {
        content: "";
        width: $ellipse-width;
        height: $ellipse-width;
        background-color: $ellipse-color;
        border-radius: 50%;
        position: absolute;
        top: -$ellipse-distance;
        left: 0;
      }

      &::after {
        content: "";
        width: $ellipse-width;
        height: $ellipse-width;
        background-color: $ellipse-color;
        border-radius: 50%;
        position: absolute;
        bottom: -$ellipse-distance;
        left: 0;
      }
    }
  }

  .social_link_editor_inputs_container {
    width: 95%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .social_link_editor_small_label {
      font-size: $small-font-size;
      color: $text-color-dark;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .enable_checkbox_container {
      width: 5%;

      .checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          font-size: $normal-font-size;
          font-weight: bold;
          color: $text-color-dark;
        }

        // Box.
        & + label:before {
          content: "";
          margin-right: 10px;
          display: block;
          width: 20px;
          height: 20px;
          background: white;
          border: $image-upload-drop-zone-border;
          border-radius: 3px;
          margin: 22px 0 0 10px;
        }

        // Box hover
        &:hover + label:before {
          background: $element-active-color;
        }

        // Box focus
        &:focus + label:before {
          box-shadow: $component-box-shadow;
        }

        // Box checked
        &:checked + label:before {
          background: $element-active-color;
        }

        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: "";
          position: absolute;
          left: 15px;
          top: 22px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }

    .link_input_field {
      width: 90%;

      .link_input_field_inner {
        position: relative;

        .social_link_url {
          width: 100%;
          display: block;
          padding: 14px 12px 14px 45px;
          border: 1px solid #e8e8e8;
          border-radius: 5px;
          background-color: #fff;
          font-size: 16px;
          outline: none;
        }

        .social_link_icon {
          position: absolute;
          width: 30px;
          top: 10px;
          left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .single_social_link_editor {
    .move_icon {
      .ellipses {
        margin-right: 0;
      }
    }

    .social_link_editor_inputs_container {
      .link_input_field {
        width: 82%;
      }
    }
  }
}
