@import "../../../assets/styles/variables.scss";

.ConfrimationPopup {
  width: 100%;
  position: relative;
  padding-top: 70px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
  }

  .icon {
    width: 110px;
    height: 110px;
    padding: 16px;
    background: #f0f0f0;
    border: 3px solid #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    border-radius: 60px;
    position: absolute;
    top: -70px;
    left: calc(50% - 55px);

    img {
      display: block;
      height: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    margin-bottom: 12px;
  }

  .sub_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: $text-color-dark;
    text-align: center;

    a {
      color: $element-active-color;
      text-decoration: none;

      &:visited {
        color: $element-active-color;
        text-decoration: none;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 25px;

    .button {
      width: 50%;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .ConfrimationPopup {
    .buttons {
      .button {
        padding: 0 5px;
      }
    }
  }
}
