@import "../../../assets/styles/global.scss";

.font_picker_container {
  width: 100%;

  .font_styles_container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    label {
      margin-right: 30px;
      margin-bottom: 12px;
      display: block;
    }

    .font_style_svg {
      position: absolute;
      width: 24px;
      height: 24px;

      span,
      svg {
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .font_picker_container {
    .font_styles_container {
      label {
        margin-right: 60px;
        margin-bottom: 20px;
      }
    }
  }
}
