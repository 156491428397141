@import "../../../assets/styles/global.scss";

.addMoreButton {
  display: block;
  width: 100%;
  margin: 15px auto;
  padding: 15px 20px;
  color: $element-active-color;
  background-color: $element-light-blue-color;
  font-size: $normal-font-size;
  font-weight: 700;
}
