@import "../../../../../assets/styles/variables.scss";

.yt_channel_details {
  display: block;
  margin-top: 10px;

  .header {
    display: flex;
    flex-direction: row;

    .thumbnail {
      width: 120px;
      height: 120px;

      img {
        display: block;
        width: 100%;
      }
    }

    .title {
      width: calc(100% - 120px);
      padding: 12px;
      display: flex;
      align-items: center;
      text-align: left;

      h3 {
        font-weight: bold;
        font-size: 22px;
        color: #000000;
      }
    }
  }

  .description {
    margin-top: 8px;
    font-size: 16px;
    color: $text-color-dark;
  }
}
