.vertical_align_center {
  display: flex;
  align-items: center;
}
.mtop_0 {
  margin-top: 0px !important;
}
.mtop_10 {
  margin-top: 10px !important;
}
.mtop_25 {
  margin-top: 25px !important;
}
.no_margins {
  margin: unset !important;
}
.padding_left_right_30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.inline_50 {
  width: 50% !important;
  display: inline-block !important;
}
.width_100 {
  width: 100% !important;
}
.vertical_space {
  margin: 20px auto;
  width: 100%;
  display: block;
}
.ellipses {
  $ellipse-width: 3px;
  $ellipse-distance: 8px;
  $ellipse-color: #afafaf;
  width: $ellipse-width;
  height: $ellipse-width;
  background-color: $ellipse-color;
  margin-right: 20px;
  border-radius: 50%;
  position: relative;
  cursor: move;

  &::before {
    content: "";
    width: $ellipse-width;
    height: $ellipse-width;
    background-color: $ellipse-color;
    border-radius: 50%;
    position: absolute;
    top: -$ellipse-distance;
    left: 0;
  }

  &::after {
    content: "";
    width: $ellipse-width;
    height: $ellipse-width;
    background-color: $ellipse-color;
    border-radius: 50%;
    position: absolute;
    bottom: -$ellipse-distance;
    left: 0;
  }
}
hr {
  background-color: rgba(175, 175, 175, 0.25);
  opacity: 0.25;
  margin: 25px auto;
  height: 1px;
}
.slick-slider {
  margin-bottom: 35px;
}
.gallery_preview .slick-slider {
  margin-bottom: 15px;
}
.slick-dots {
  width: auto !important;

  li {
    margin: 0 0px !important;

    button:before {
      font-size: 10px !important;
      color: $text-color-dark !important;
    }

    &.slick-active {
      button:before {
        color: $element-active-color !important;
        opacity: 1 !important;
      }
    }
  }
}
.slick-prev:before,
.slick-next:before {
  color: $text-color-dark !important;
}
.slick-next {
  right: 0px !important;
  bottom: -30px !important;
  top: unset !important;
}
.slick-prev {
  left: unset !important;
  right: 35px !important;
  bottom: -30px !important;
  top: unset !important;
}

.gallery_preview .slick-prev {
  left: -20px !important;
  bottom: -1px !important;
}
.gallery_preview .slick-next {
  right: -20px !important;
  bottom: -1px !important;
}

/* Skeleton UI */
// @keyframes react-loading-skeleton {
//   100% {
//     transform: translateX(100%);
//   }
// }

// .react-loading-skeleton {
//   --base-color: #ebebeb;
//   --highlight-color: #f5f5f5;
//   --animation-duration: 1.5s;
//   --animation-direction: normal;
//   --pseudo-element-display: block; /* Enable animation */

//   background-color: var(--base-color);

//   width: 100%;
//   border-radius: 0.25rem;
//   display: inline-flex;
//   line-height: 1;

//   position: relative;
//   overflow: hidden;
//   z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

//   &::after {
//     content: " ";
//     display: var(--pseudo-element-display);
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 100%;
//     background-repeat: no-repeat;
//     background-image: linear-gradient(
//       90deg,
//       var(--base-color),
//       var(--highlight-color),
//       var(--base-color)
//     );
//     transform: translateX(-100%);

//     animation-name: react-loading-skeleton;
//     animation-direction: var(--animation-direction);
//     animation-duration: var(--animation-duration);
//     animation-timing-function: ease-in-out;
//     animation-iteration-count: infinite;
//   }
// }
