@import "./variables.scss";
// @import "./fonts.scss";
@import "./helper.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: opacity 150ms ease-in-out;
}
body {
  // font-family: jakartaSans, sans-serif;
  font-family: "Lato", sans-serif;
  background-color: $background-color;

  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: block;
    background-color: #e5e5e5;
    padding: 0;
  }

  &.webflow {
    .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      display: block;
      background-color: #f1f8fc;
    }
  }
}
button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
}
