@import "../../../../../assets/styles/global.scss";

.social_icons_style_selectors_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .social_icons_style_selectors_container {
    justify-content: flex-start;
  }
}
