@import "../../../assets/styles/variables.scss";

.image_gallery_viewer {
  position: relative;
  width: 100%;
  margin: 15px auto;
  padding: 15px 20px;
  border-radius: $preview-section-border-radius;
  background-color: #222f4f;

  .image_gallery_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .image_gallery_heading {
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
    }

    .image_gallery_icon {
      display: block;
      $icon-size: 25px;
      width: $icon-size;
      height: $icon-size;

      img,
      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .image_gallery_tagline {
    font-size: 14px;
    color: #ffffff8a;
  }

  .sliders_container {
    margin: 10px auto;
    width: 100%;

    .gallery_slide {
      height: 130px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: none;
      }
    }

    .gallery_cta {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #daf0ff;
      padding: 10px 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      overflow: hidden;

      .cta_icon {
        width: 12%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: flex;
        }

        img {
          display: block;
          width: 65%;
        }
      }

      .cta_text_url {
        width: 85%;
        padding-left: 5px;

        a {
          color: #31a9ff;
          font-size: 12px;
          font-weight: 400;
          text-decoration: none;
        }
      }
    }

    .sliders_divider {
      display: block;
      height: 1px;
      width: 100%;
      background-color: #f1f1f147;
      margin: 10px auto;
    }

    .gallery_slide_bottom {
      height: 50px;
      position: relative;
      text-align: center;
      padding: 4px;

      .thumbnail {
        height: 100%;
        border-radius: 6px;
        overflow: hidden;

        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
