@import "../../../assets/styles/variables.scss";

.footer {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 50px;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  background-color: $default-section-bg;

  .logo {
    display: block;
    width: 70%;
    margin: 0 auto;
  }

  .text {
    color: $text-color-light;
    font-size: $supersmall-font-size;
    text-align: center;
    margin: 8px auto;
  }

  .cta {
    display: block;
    width: 100%;
    padding: 14px;
    margin: 10px auto;
    background-color: #3141ff;
    color: #ffffff;
    font-size: $supersmall-font-size;
    border-radius: $preview-section-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    &:visited {
      color: #ffffff;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .link {
      text-decoration: none;
      color: $text-color-light;
      font-size: $supersmall-font-size;
      cursor: pointer;
      user-select: none;

      &:visited {
        color: $text-color-light;
      }
    }
  }
}
