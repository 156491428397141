@import "../../../assets/styles/variables.scss";

.personal_details_viewer {
  width: 100%;
  position: relative;
  $secondary-text-color: #222f4f;

  .contact_div {
    position: relative;
    overflow: hidden;
  }

  .social_links_div {
    background-color: $secondary-text-color;
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    a {
      display: inherit;
      text-decoration: none;
      $social_icon_size: 72px;
      width: 25%;
      height: $social_icon_size;
      padding: 6px;
      margin: 4px 0;

      .social_icon_display_container {
        width: 100%;
        height: 100%;
        border: 1px solid black;
        border-radius: 50%;

        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 50%;
            height: auto;
            max-height: 50%;

            path {
              fill: #fff;
              stroke: #000;
            }
          }
        }

        &.social_icon_style_2 {
          background-color: #000;

          svg path {
            fill: #fff;
            stroke: none;
          }
        }
        &.social_icon_style_3 {
          border-radius: 0;

          svg path {
            fill: #000;
            stroke: none;
          }
        }
        &.social_icon_style_4 {
          border-radius: 0;
          background-color: #000;

          svg path {
            fill: #fff;
            stroke: none;
          }
        }
        &.social_icon_style_5 {
          border-radius: 6px;

          svg path {
            fill: #000;
            stroke: none;
          }
        }
        &.social_icon_style_6 {
          border-radius: 6px;
          background-color: #000;

          svg path {
            fill: #fff;
            stroke: none;
          }
        }
        &.social_icon_style_7 {
          border-radius: 6px;
          background-color: #388acc;
          border-color: #388acc;

          svg path {
            fill: #fff;
            stroke: none;
          }
        }
        &.social_icon_style_8 {
          border-radius: 50%;
          background-color: #388acc;
          border-color: #388acc;

          svg path {
            fill: #fff;
            stroke: none;
          }
        }
      }
    }
  }

  &.layout_1 {
    $main-text-color: #ffffff;

    .business_logo {
      display: block;
      // max-width: 90%;
      // max-height: 60px;
      margin: 10px auto;
    }

    .details_box {
      background-color: $secondary-text-color;
      height: 192px;
      border-bottom: 1px solid #f1f1f147;
      border-radius: 10px 10px 0 0;
      margin-top: 135px;

      .profile_main_info {
        width: 100%;
        transform: translateY(-120px);

        .business_name,
        .user_name {
          text-align: center;
          color: $main-text-color;
          font-size: 24px;
        }

        .business_name {
          color: $secondary-text-color;
        }

        .profile_picture {
          width: 170px;
          height: 170px;
          margin: 10px auto;
          border-radius: 10px;
          background-color: #fff;
          margin-bottom: 20px;
          overflow: hidden;
          box-shadow: $component-box-shadow;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &.rounded_border {
            border-radius: 10px;
          }

          &.square_border {
            border-radius: 0px;
          }

          &.circle_border {
            border-radius: 50%;
          }
        }

        .business_role {
          color: $main-text-color;
          font-size: 16px;
          text-align: center;
          margin-top: 15px;
          opacity: 0.8;
        }
      }
    }

    .contact_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-radius: 0 0 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .contact_pill {
        width: 22%;
        text-decoration: none;
        outline: none;
        display: flex;
        flex-direction: column;

        svg {
          display: block;
          margin: 4px auto 10px auto;
        }

        .contact_pill_text {
          color: $main-text-color;
          font-size: 14px;
          width: 100%;
          text-align: center;
        }
      }
    }

    .social_links_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &.layout_2 {
    $main-text-color: #ffffff;
    // $secondary-text-color: #3b4966;

    .business_logo {
      display: block;
      // max-width: 90%;
      // max-height: 60px;
      margin: 10px auto;
    }

    .details_box {
      background-color: $secondary-text-color;
      height: 230px;
      border-radius: 10px 10px 0 0;
      margin-top: 95px;

      .profile_main_info {
        width: 100%;
        transform: translateY(-80px);

        .business_name,
        .user_name {
          text-align: center;
          color: $main-text-color;
          font-size: 24px;
        }

        .business_name {
          color: $secondary-text-color;
        }

        .profile_picture {
          width: 170px;
          height: 170px;
          margin: 10px auto;
          border-radius: 10px;
          background-color: #fff;
          margin-bottom: 20px;
          overflow: hidden;
          box-shadow: $component-box-shadow;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &.rounded_border {
            border-radius: 10px;
          }

          &.square_border {
            border-radius: 0px;
          }

          &.circle_border {
            border-radius: 50%;
          }
        }

        .business_role {
          color: $main-text-color;
          font-size: 16px;
          text-align: center;
          margin-top: 15px;
          opacity: 0.8;
        }
      }
    }

    .contact_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-radius: 0 0 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .contact_pill {
        width: 22%;
        text-decoration: none;
        outline: none;
        display: flex;
        flex-direction: column;

        svg {
          display: block;
          margin: 4px auto 10px auto;
        }

        .contact_pill_text {
          color: $main-text-color;
          font-size: 14px;
          width: 100%;
          text-align: center;
        }
      }
    }

    .social_links_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &.layout_3 {
    $main-text-color: #ffffff;
    // $secondary-text-color: #3b4966;

    .business_logo_container {
      background-color: $secondary-text-color;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #f1f1f147;
      padding: 10px 0;
      margin-top: 20px;

      .business_logo {
        display: block;
        // max-width: 90%;
        // max-height: 60px;
        margin: 10px auto;
      }
    }

    .details_box {
      background-color: $secondary-text-color;
      border-radius: 0 0 10px 10px;

      .profile_main_info {
        width: 100%;
        padding: 20px 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .profile_picture {
          width: 50%;
          max-height: 150px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: $component-box-shadow;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &.rounded_border {
            border-radius: 10px;
          }

          &.square_border {
            border-radius: 0px;
          }

          &.circle_border {
            border-radius: 50%;
          }
        }

        .profile_info {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 5px;

          .user_name {
            text-align: center;
            color: $main-text-color;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 8px;
          }

          .business_role {
            color: $main-text-color;
            font-size: 14px;
            margin-bottom: 8px;
          }

          .business_name {
            color: $main-text-color;
            font-size: 12px;
            margin-bottom: 8px;
            opacity: 0.8;
          }
        }
      }
    }

    .contact_div {
      background-color: $secondary-text-color;
      padding: 10px 8px;
      margin-top: 10px;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .contact_pill {
        width: 22%;
        text-decoration: none;
        outline: none;
        display: flex;
        flex-direction: column;

        svg {
          display: block;
          margin: 4px auto 10px auto;
        }

        .contact_pill_text {
          color: $main-text-color;
          font-size: 14px;
          width: 100%;
          text-align: center;
        }
      }
    }

    .social_links_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-top: 1px solid #f1f1f147;
      margin-top: 0;
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &.layout_4 {
    $main-text-color: #ffffff;
    // $secondary-text-color: #3b4966;

    .details_box {
      background-color: $secondary-text-color;
      border-radius: 10px;
      margin-top: 20px;

      .profile_main_info {
        width: 100%;
        padding: 15px 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .profile_picture,
        .business_logo_container {
          width: 90px;
          height: 90px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: $component-box-shadow;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &.rounded_border {
            border-radius: 10px;
          }

          &.square_border {
            border-radius: 0px;
          }

          &.circle_border {
            border-radius: 50%;
          }
        }

        .business_logo_container img {
          object-fit: contain;
        }
      }
    }

    .profile_info {
      background-color: $secondary-text-color;
      border-radius: 10px;
      margin-top: 10px;
      padding: 15px 20px;

      .user_name {
        text-align: center;
        color: $main-text-color;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 8px;
      }

      .business_info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 8px 0 6px 0;

        .business_role,
        .business_name {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $main-text-color;
          font-size: 14px;
        }

        .business_name {
          border-left: 2px solid #f1f1f1;
          color: $main-text-color;
          font-size: 14px;
          opacity: 0.8;
        }
      }
    }

    .contact_div {
      background-color: $secondary-text-color;
      padding: 10px 8px;
      margin-top: 10px;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .contact_pill {
        width: 22%;
        text-decoration: none;
        outline: none;
        display: flex;
        flex-direction: column;

        svg {
          display: block;
          margin: 4px auto 10px auto;
        }

        .contact_pill_text {
          color: $main-text-color;
          font-size: 14px;
          width: 100%;
          text-align: center;
        }
      }
    }

    .social_links_div {
      background-color: $secondary-text-color;
      padding: 10px 10px 20px 10px;
      border-top: 1px solid #f1f1f147;
      margin-top: 0;
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
