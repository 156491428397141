@import "../../../assets/styles/variables.scss";

.chart_container {
  display: flex;
  justify-content: center;
}

.list_container {
  margin: 30px 0px;

  .list_item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    color: $text-color-dark;

    .value {
      font-weight: 700;
    }

    &::after {
      content: "";
      // display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e2e2e2;
    }
  }
}

.bar_chart {
  margin: 20px 0px;

  .bar_container {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  .label {
    width: 150px;
    // text-align: left;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    color: $text-color-light;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-bottom: 1px;
    }

    svg path,
    svg rect {
      fill: #75d89d;
    }
  }

  .bar {
    height: 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
  }
  
  .bar:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
}

