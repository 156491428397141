@import "../../assets/styles/variables.scss";

.live_preview {
  width: 25%;
  padding: $section-inner-padding;
  transition: all 150ms ease-in-out;

  * {
    transition: all 150ms ease-in-out;
  }

  .mobile_preview_button_float {
    display: none;
  }

  .live_preview_inner {
    .profile_link_heading {
      font-size: $small-font-size;
      color: $text-color-light;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .profile_link_share {
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: $normal-font-size;
      color: $text-color-light;
      background-color: $component-bg-color;
      box-shadow: $component-box-shadow;
      margin-bottom: 5px;
      width: 100%;

      a {
        text-decoration: none;
        user-select: none;

        &:visited {
          color: $text-color-light;
        }
      }

      .share_profile_link {
        position: relative;
        display: flex;

        .share_btns {
          width: 20px;
          height: 20px;
          margin-bottom: -4px;
          margin-left: 6px;
          display: inline-block;

          span {
            display: block;
            width: 100%;
            height: 100%;

            svg {
              width: 100%;
              height: 100%;

              &:hover {
                path {
                  fill: $element-active-color;
                }
              }
            }
          }
        }

        .copied {
          color: $element-active-color;
          font-size: 11px;
          text-align: left;
          position: absolute;
          top: 0px;
          left: -4px;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .share_buttons {
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .share_grey_btn {
        padding: 11px 6px;
        background: #eaeaea;
        border-radius: 4px;
        outline: none;
        border: none;
        user-select: none;
        width: 49%;
        min-height: 92px;

        .icon {
          display: block;
          width: 30%;
          margin: 0 auto;
          margin-bottom: 10px;

          span svg {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        p {
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 130%;
          text-align: center;
          color: #505050;

          a {
            text-decoration: none;
            color: inherit;

            &:visited {
              color: inherit;
            }
          }
        }

        &:hover {
          p {
            fill: $element-active-color;
            color: $element-active-color;
          }
        }
      }
    }

    .live_preview_phone {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc(60vh);
      max-height: 700px;
      background: #ffffff;
      border-radius: 32px;
      border: 7px solid #070707;

      .phone_top_bar {
        width: 100%;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;

        .time_block,
        .time_info_block {
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .time_info_block img {
          width: 80%;
        }

        .notch {
          width: 50%;
          background-color: #000000;
          height: 100%;
          border-radius: 0 0 50px 50px;
        }
      }

      .preview_wrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          width: 0px;
          border-radius: 2px;
        }

        // Bottoms styles if I ever want to style the scrollboar for mobile,
        // but for mobile effect, keeping it hidden
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: $text-color-light;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }

  .qr_code {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999999;
    transition: all 300ms ease-in-out;

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .live_preview {
    padding-right: 0;
  }
}

@media only screen and (max-width: $medium-breakpoint) {
  .live_preview {
    width: 35%;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .live_preview {
    width: 100%;
    padding: $section-inner-padding-mobile;
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    z-index: 99;
    backdrop-filter: blur(10px);
    transform: translateY(calc(100% - 40px));

    &.open {
      transform: translateY(0);
    }

    .mobile_preview_button_float {
      width: 100%;
      display: block;
      background-color: $element-light-blue-color;
      color: $element-active-color;
      font-size: $normal-font-size;
      font-weight: bold;
      height: 40px;
      margin-bottom: 10px;
    }

    .live_preview_inner {
      padding: 0 10px;

      .share_buttons {
        display: none;
      }

      .live_preview_phone {
        height: 70vh;
      }
    }

    .live_preview_phone {
      .preview_phone_bg {
        width: unset;
        height: calc(100vh - 250px);
      }
    }
  }
}
