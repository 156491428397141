@import "../../assets/styles/variables.scss";

.notifications {
  width: 90%;
  max-width: 360px;
  position: fixed;
  top: 110px;
  right: 25px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .notifications {
    right: 5%;
  }
}
