@import "../../../assets/styles/global.scss";

.drop_upload_file {
  width: 100%;
  background-color: $component-bg-color;
  border: $image-upload-drop-zone-border;
  text-align: center;
  margin-top: $editable-section-top-margin;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .dropper {
    width: 100%;

    p {
      font-size: $normal-font-size;
      color: $text-color-light;
      padding: 40px 20px;

      svg {
        margin-right: 5px;
      }
    }

    &:hover {
      p {
        color: $element-active-color;

        svg path {
          fill: $element-active-color;
        }
      }
    }
  }

  .existing_preview {
    display: none;
  }

  &.with_preview {
    border: none;

    .dropper {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: $image-upload-drop-zone-border;
    }

    .existing_preview {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: $image-upload-drop-zone-border;

      p {
        font-size: $normal-font-size;
        color: $text-color-light;
        margin-bottom: 6px;
        margin-top: 12px;
      }

      .frame {
        border: 5px solid #ddd8d8;
        width: 120px;
        height: 120px;
        margin-bottom: 12px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .delete_icon {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;
    padding: 7px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .drop_upload_file {
    p {
      font-size: $small-font-size;
    }

    .delete_icon {
      right: -12px;
      top: -12px;
      width: 25px;
      height: 25px;
      padding: 5px;
    }
  }
}
