@import "../../assets/styles/variables.scss";

.login_options {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .login_option {
    width: 31%;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    * {
      transition: all 200ms ease-in-out;
    }

    .login_option_logo {
      width: 30px;
      margin: 5px auto;

      span,
      svg {
        width: 100%;
        height: 30px;
        fill: #000000;
      }
    }

    .logo_option_text {
      font-size: 10px;
      color: #505050;
      width: 100%;
      text-align: center;
    }

    &:hover,
    &.active {
      background-color: $element-active-color;

      svg path,
      svg rect {
        stroke: #fff;
        fill: #fff;
      }

      .logo_option_text {
        color: #fff;
      }
    }
  }
}

.user_form {
  width: 100%;
  margin: 10px auto;

  .forgot_text {
    font-size: 12px;
    line-height: 18px;
    color: $element-active-color;
    outline: none;
    border: none;
    margin: 12px auto;
  }

  button[type="submit"] {
    background-color: $element-active-color;
    padding: 12px;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    display: block;
    width: 100%;
    margin: 5px auto;
    border-radius: 4px;
  }

  .error_message {
    font-size: 15px;
    line-height: 19px;
    color: $danger-color;
    text-align: left;
    margin: 0px 0px 12px 0;
    width: 100%;
    display: none;

    &.show {
      display: block;
    }
  }

  .info_message {
    font-size: 15px;
    line-height: 19px;
    color: $element-active-color;
    text-align: left;
    margin: 0px 0px 12px 0;
    width: 100%;
    display: none;

    &.show {
      display: block;
    }
  }
}

.second_option_text {
  font-size: 15px;
  line-height: 21px;
  color: #858585;
  text-align: center;
  margin: 20px auto;

  a {
    color: #000;
  }
}

.new_section {
  margin-top: 20px;
}

.small_heading {
  font-size: $small-font-size;
  color: $text-color-dark;
  font-weight: bold;
  margin-bottom: 20px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .login_options {
    .login_option {
      .logo_option_text {
        min-height: 25px;
      }
    }
  }
}
