@import "../../../../assets/styles/global.scss";

.header_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .header_left,
  .header_right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header_left .collapsible_header_icon {
    span,
    svg {
      height: 100%;
    }
  }

  .header_right {
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
    }
  }

  .collapsible_heading {
    font-size: 20px;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
    color: $text-color-dark;

    .collapsible_subtitle {
      font-size: 12px;
      color: $text-color-light;
      font-weight: normal;
      display: block;
      margin-top: 5px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 23px;

    & + span {
      margin-left: 15px;
      height: 100%;
    }
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $element-active-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $element-active-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 22px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.container_smaller_header {
    margin-bottom: 10px;

    .collapsible_heading {
      font-size: 16px;
      margin-left: 8px;

      .collapsible_subtitle {
        font-size: 10px;
      }
    }

    .switch {
      transform-origin: left;
      transform: scale(0.8);
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .header_container {
    .collapsible_heading {
      font-size: 16px;
      margin: 0 8px;
    }

    .switch {
      width: 38px;
      height: 18px;

      & + span {
        margin-left: 15px;
        height: 100%;
      }

      .slider:before {
        height: 13px;
        width: 13px;
        bottom: 3px;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
    }
  }
}
