@import "../../../assets/styles/variables.scss";

.hide_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background-color: #161f36ba;
  backdrop-filter: blur(4px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  &.thick_hide {
    background-color: #161f36ed;
    backdrop-filter: blur(8px);
  }

  .lock {
    width: 22px;
    height: 22px;
    display: block;
    margin-right: 6px;

    span {
      display: flex;
      width: 100%;
      height: 100%;

      svg {
        width: 100%;

        path {
          fill: $lock-color;
        }
      }
    }
  }

  .lock_text {
    color: $lock-color;
    font-size: 14px;
    font-weight: bold;
  }
}
