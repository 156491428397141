@import "../../../assets/styles/variables.scss";

.analytic_section {
  position: relative;
  background-color: $designer-component-bg;
  padding: 20px 16px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .date_selection {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    span {
      display: flex;
    }

    svg {
      margin-right: 8px;

    }
  }

  .calendar_container {
    margin-top: 1rem;
  }
}
