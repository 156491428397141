@import "../../assets/styles/global.scss";

.auth_container {
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form_outer_container {
    width: 70%;
    height: 100%;

    .logo_container {
      width: 100%;
      padding: 20px;

      .logo {
        height: 50px;
        width: auto;
      }
    }

    .form_inner_container {
      width: 100%;
      min-height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .form_center {
        width: 90%;
        margin: 50px auto;
        max-width: 450px;

        &.wide {
          max-width: 750px;
        }
      }

      .main_heading {
        font-size: 26px;
        line-height: 34px;
        color: #354640;
      }

      .help_text {
        font-size: 14px;
        line-height: 21px;
        color: #858585;
      }

      .form_contents {
        width: 100%;
        margin: 20px auto;
      }
    }
  }

  .cover_image {
    width: 30%;
    background-image: url(../../assets/authFlow/cover.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .auth_container {
    display: block;

    .form_outer_container {
      width: 100%;

      .form_inner_container {
        .main_heading {
          font-size: 22px;
        }
      }
    }

    .cover_image {
      display: none;
    }
  }
}
